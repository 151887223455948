import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../../container/SearchResults/logic'

const GET_PROJECT_SUMMARY = 'GET_PROJECT_SUMMARY'
const GET_PROJECT_SUMMARY_SUCCESS = 'GET_PROJECT_SUMMARY_SUCCESS'
const GET_PROJECT_SUMMARY_FAILURE = 'GET_PROJECT_SUMMARY_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getProjectSummaryAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: GET_PROJECT_SUMMARY,
      url: `${process.env.apiUrl}v0/projects/getProjectSummary?${stringify(payload)}`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: meta.markInvalid ? 'PI is marked as Invalid' : 'PI is marked as valid',
    // hideNotification: false, // hide notification on error
  })
}

export function getProjectSummaryDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PROJECT_SUMMARY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PROJECT_SUMMARY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    // case GET_PROJECT_SUMMARY_RESET: {
    //   return {
    //     data: {},
    //     loading: false,
    //     flag: false,
    //     error: false,
    //   }
    // }
    case GET_PROJECT_SUMMARY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
