import { parse } from 'query-string';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../common/components/Loader';
import { getTabsDeepdiveListAction, tabsDeepdiveResetAction } from '../../container/DeepDiveScreen/logic';

export default function CTDetailsResultDeepDive() {
  const dispatch = useDispatch();
  const [tabsTrialDataObj, setTabsTrialDataObj] = useState([])
  const { cid } = parse(location.search)
  const { idx } = parse(location.search)
  const tabsDeepdiveResponse = useSelector((store) => store.tabsDeepdiveResponse)

  useEffect(() => {
    dispatch(getTabsDeepdiveListAction({
      clinical_id: cid,
      tabname: 'Results',
    }))
  }, [])

  useEffect(() => {
    if (tabsDeepdiveResponse.flag) {
      setTabsTrialDataObj(tabsDeepdiveResponse.data.data.results.Primary[idx])
      dispatch(tabsDeepdiveResetAction())
    }
  }, [JSON.stringify(tabsDeepdiveResponse)])

  return (
    <div className="main-container">
      <div id="ontosight-details-tab-content" className="tabs-content scrollbar">
        <Loader loading={tabsDeepdiveResponse.loading} error={tabsDeepdiveResponse.error}>
          <div className="result-deepdivescreen">
            <div className="tab-content">
              <div className="result-deepdivescreen-type">{tabsTrialDataObj.type}</div>
              <div className="result-deepdivescreen-data">
                <div className="result-deepdivescreen-title">{tabsTrialDataObj.title}</div>
                <div className="result-deepdivescreen-desc">{tabsTrialDataObj.time_frame}</div>
              </div>
              <div className="result-deepdivescreen-data">
                <div className="result-deepdivescreen-title"> MEASURE DESCRIPTION </div>
                <div className="result-deepdivescreen-desc">{tabsTrialDataObj.measure_description}</div>
              </div>
              <div className="result-deepdivescreen-data">
                <div className="result-deepdivescreen-title"> POPULATION DESCRIPTION </div>
                <div className="result-deepdivescreen-desc">{tabsTrialDataObj.population_description}</div>
              </div>
              <div className="result-deepdivescreen-table">
                <div className="result-deepdivescreen-title">OUTCOME MEASURES</div>
                <div className="result-deepdivescreen-table-content">{tabsTrialDataObj && tabsTrialDataObj?.tableData?.title?.map((itm) => {
                  return (
                    <div className="result-deepdivescreen-table-content-value">
                      <div className="result-deepdivescreen-table-content-value-title">{itm}</div>
                      <div className="result-deepdivescreen-table-content-value-data">
                        {Object.keys(tabsTrialDataObj?.tableData?.data).map((k) => {
                          return (
                            <div className="result-deepdivescreen-table-content-value-data-in">
                              <div className="result-deepdivescreen-table-content-value-data-in-sec">
                                {tabsTrialDataObj && itm === 'no-title-here-0' ? (
                                  <>
                                    <div className="result-deepdivescreen-table-content-value-data-in-sec-value">{tabsTrialDataObj?.tableData?.data[k][itm].value}</div>
                                    <div className="result-deepdivescreen-table-content-value-data-in-sec-data">{tabsTrialDataObj?.tableData?.data[k][itm].range}</div>
                                  </>
                                ) : (
                                  <div>{tabsTrialDataObj?.tableData?.data[k][itm]}</div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
                </div>

              </div>
            </div>
          </div>
        </Loader>
      </div>
    </div>
  )
}
