import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Form, Modal, Steps,
} from 'antd';
import { parse } from 'query-string';
import GeneralInformation from '../../container/GeneralInformation';
import Loader from '../../../common/components/Loader';
import Facilities from '../../container/Facilities';
import PatientAccess from '../../container/PatientAccess';

import {
  addParentAction,
  addSiteResetAction, createSiteAction, getSiteQuesDataAction, updateParentAction, updateSiteAction,
} from './logic';
import { getDeepdiveValuesAction } from '../../container/DeepDiveScreen/logic';
import { getDatasetListDataAction } from '../../container/SearchResults/logic';

import { blankGeneralInformation } from './defaultKeys';
import { getCenterTypeDataAction, getCountryDataAction, getStateDataResetAction } from '../../container/GeneralInformation/logic';
import { setAnalytics, hasPermissionForAction, getCookie } from '../../../utils';
import { getInstituteDeepdiveListAction } from '../../container/InstitutionDetails/logic';
import { ListUsersAction } from '../../container/ShareProjectModal/logic';
import { getPiFilterDataAction } from '../AddInvestigator/logic';
import SiteOtherPersonnel from '../../container/SiteOtherPersonnel';
import CenterComplianceIssue from '../../container/CenterComplianceIssues';

export default function AddCentersManuallyAddNew({
  setDefaultValues, flag, setEditModal, setEditCenterModal,
}) {
  const dispatch = useDispatch();
  const updateSiteResponse = useSelector((store) => store.updateSiteResponse);
  const createSiteResponse = useSelector((store) => store.createSiteResponse);
  const defaultValues = useSelector((state) => state.deepDive);
  const instituteDeepdiveList = useSelector((store) => store.instituteDeepdiveList);
  const [addOtherCenter, setAddOtherCenter] = useState(false)
  const [isSimsIdVerified, setIsSimsIdVerified] = useState(true)
  const [simsId, setSimsId] = useState('')

  const defaultCompliance = {}
  const defaultCDD = {}

  const { id } = parse(location.search)
  const { query } = parse(location.search)

  const [countryForNpiNumber, setCountryForNpiNumber] = useState('')
  const [alertModalForNpiID, setAlertModalForNpiID] = useState(false)
  const [warningFlag, setWarningFlag] = useState(true)
  const [jumpToPage, setJumpToPage] = useState(0)
  const [flagForApiCall, setFlagForApiCall] = useState('')
  const [noParent, setNoParent] = useState(false)

  const profile_responses = {}
  if (parse(location.search).currentTab === 'parent_centers') {
    if (instituteDeepdiveList?.data?.data?.profile_responses) {
      instituteDeepdiveList.data.data.profile_responses.forEach((item) => {
        if (item.response.length >= 2) {
          profile_responses[item.question_id] = item.response;
        } else {
          profile_responses[item.question_id] = item.response && item.response[0];
        }
      })
    }
  } else if (defaultValues?.data?.profile_responses) {
    defaultValues.data.profile_responses.forEach((item) => {
      if (item.response.length >= 2) {
        profile_responses[item.question_id] = item.response;
      } else {
        profile_responses[item.question_id] = item.response && item.response[0];
      }
    })
  }

  const defaultCenters = {};
  let defaultOthers = [];
  const idAlready = []

  const configRequired = {
    0: ['centerName', 'address_line1', 'city', 'state', 'country', 'postalcode', 'telephoneNo', 'center_type'],
  }

  const configMandatory = {
    0: ['centerName', 'address_line1', 'city', 'country', 'postalcode', 'telephoneNo', 'center_type'],
  }
  if (setDefaultValues && flag === 'editCenter') {
    if (parse(location.search).currentTab === 'parent_centers') {
      defaultCenters.centerName = { value: instituteDeepdiveList?.data?.data?.name, flag: false }
      defaultCenters.solo_center_flag = { value: instituteDeepdiveList?.data?.data?.solo_center_flag, flag: false }
      defaultCenters.fax = { value: instituteDeepdiveList?.data?.data?.fax, flag: false }
      defaultCenters.url = { value: instituteDeepdiveList?.data?.data?.url, flag: false }
      defaultCenters.telephoneNo = { value: instituteDeepdiveList?.data?.data?.phone_number, flag: false }
      defaultCenters.mobileNo = { value: instituteDeepdiveList?.data?.data?.mobile_number, flag: false }
      defaultCenters.primary_center = { value: instituteDeepdiveList?.data?.data?.affiliated_site, flag: false }
      defaultCenters.email = { value: instituteDeepdiveList?.data?.data?.email.toString(), flag: false }
      defaultCenters.address_line1 = { value: instituteDeepdiveList?.data?.data?.address?.address_line1, flag: false }
      defaultCenters.address_line2 = { value: instituteDeepdiveList?.data?.data?.address?.address_line2, flag: false }
      defaultCenters.city = { value: instituteDeepdiveList?.data?.data?.address?.city, flag: false }
      defaultCenters.state = { value: instituteDeepdiveList?.data?.data?.address?.state, flag: false }
      defaultCenters.country = { value: instituteDeepdiveList?.data?.data?.address?.country, flag: false }
      defaultCenters.postalcode = { value: instituteDeepdiveList?.data?.data?.address?.zip, flag: false }
      defaultCenters.parent_flag = { value: instituteDeepdiveList?.data?.data?.parent_flag, flag: false }
      defaultCenters.center_type = { value: instituteDeepdiveList?.data?.data?.center_type, flag: false }
      defaultCenters.pxl_manager = { value: instituteDeepdiveList?.data?.data?.pxl_relationship_managers, flag: false }
      defaultCenters.site_manager = { value: instituteDeepdiveList?.data?.data?.site_alliance_manager, flag: false }
      defaultCenters.department = { value: instituteDeepdiveList?.data?.data?.department, flag: false }
      defaultCenters.alliance_member = { value: instituteDeepdiveList?.data?.data?.alliance_member, flag: false }
      defaultCenters.alliance_member_alliance_type = { value: instituteDeepdiveList?.data?.data?.alliance_member_alliance_type, flag: false }
      defaultCenters.alliance_member_id = { value: instituteDeepdiveList?.data?.data?.alliance_member_id, flag: false }
      defaultCenters.oms_loc_id = { value: instituteDeepdiveList?.data?.data?.oms_loc_id, flag: false }
      defaultCenters.oms_org_id = { value: instituteDeepdiveList?.data?.data?.oms_org_id, flag: false }
      defaultCenters.pxl_center_sims_ids = { value: instituteDeepdiveList?.data?.data?.pxl_center_sims_ids, flag: false }
      defaultOthers = instituteDeepdiveList?.data?.data?.other_personnel?.map((itm) => {
        return {
          role: { old: itm.role, new: '', flag: false },
          role_at_site: { old: itm.role_at_site, new: '', flag: false },
          full_name: { old: itm.full_name, new: '', flag: false },
          id: { old: itm.id, new: '', flag: false },
          // first_name: { old: itm.first_name, new: '', flag: false },
          // last_name: { old: itm.last_name, new: '', flag: false },
          mobile_number: { old: itm.mobile_number, new: '', flag: false },
          fax: { old: itm.fax, new: '', flag: false },
          email: { old: itm.email, new: '', flag: false },
          same_location: { old: itm.same_location, new: '', flag: false },
          address: {
            address_line1: { old: itm.address?.address_line1, new: '', flag: false },
            address_line2: { old: itm.address?.address_line2, new: '', flag: false },
            country: { old: itm.address?.country, new: '', flag: false },
            state: { old: itm.address?.state, new: '', flag: false },
            zip: { old: itm.address?.zip, new: '', flag: false },
            city: { old: itm.address?.city, new: '', flag: false },
          },
        }
      })
      if (instituteDeepdiveList?.data?.data?.ccn_number !== '') {
        idAlready.push({
          key: 100,
          type: 'ccn_number',
          value: instituteDeepdiveList?.data?.data?.ccn_number,
          label: 'CCN Number',
        })
      }
      if (instituteDeepdiveList?.data?.data?.npi?.length) {
        for (let i = 0; i < instituteDeepdiveList?.data?.data?.npi.length; i += 1) {
          idAlready.push({
            key: 2000 + i,
            type: 'npi',
            value: instituteDeepdiveList?.data?.data?.npi[i],
            label: 'NPI Id',
          })
        }
      }
      if (instituteDeepdiveList?.data?.data?.pac_ids?.length) {
        for (let i = 0; i < instituteDeepdiveList?.data?.data?.pac_ids.length; i += 1) {
          idAlready.push({
            key: 3000 + i,
            type: 'pac_ids',
            value: instituteDeepdiveList?.data?.data?.pac_ids[i],
            label: 'PAC Id',
          })
        }
      }
      if (instituteDeepdiveList?.data?.data?.taxation_ids?.length) {
        for (let i = 0; i < instituteDeepdiveList?.data?.data?.taxation_ids.length; i += 1) {
          idAlready.push({
            key: 4000 + i,
            type: 'taxation_ids',
            value: instituteDeepdiveList?.data?.data?.taxation_ids[i],
            label: 'Taxation id',
          })
        }
      }
    } else {
      defaultCenters.centerName = { value: defaultValues?.data?.name, flag: false }
      defaultCenters.solo_center_flag = { value: defaultValues?.data?.solo_center_flag, flag: false }
      defaultCenters.fax = { value: defaultValues?.data?.fax, flag: false }
      defaultCenters.url = { value: defaultValues?.data?.url, flag: false }
      defaultCenters.telephoneNo = { value: defaultValues?.data?.phone_number, flag: false }
      defaultCenters.mobileNo = { value: defaultValues?.data?.mobile_number, flag: false }
      defaultCenters.primary_center = { value: defaultValues?.data?.affiliated_site, flag: false }
      defaultCenters.email = { value: defaultValues?.data?.email.toString(), flag: false }
      defaultCenters.address_line1 = { value: defaultValues?.data?.address?.address_line1, flag: false }
      defaultCenters.address_line2 = { value: defaultValues?.data?.address?.address_line2, flag: false }
      defaultCenters.city = { value: defaultValues?.data?.address?.city, flag: false }
      defaultCenters.ccn_number = { value: defaultValues?.data?.ccn_number, flag: false }
      defaultCenters.npi = { value: defaultValues?.data?.npi, flag: false }
      defaultCenters.taxation_ids = { value: defaultValues?.data?.taxation_ids, flag: false }
      defaultCenters.pac_ids = { value: defaultValues?.data?.pac_ids, flag: false }
      defaultCenters.pxl_center_sims_ids = { value: defaultValues?.data?.pxl_center_sims_ids, flag: false }
      defaultCenters.state = { value: defaultValues?.data?.address?.state, flag: false }
      defaultCenters.country = { value: defaultValues?.data?.address?.country, flag: false }
      defaultCenters.postalcode = { value: defaultValues?.data?.address?.zip, flag: false }
      defaultCenters.parent_flag = { value: defaultValues?.data?.parent_flag, flag: false }
      defaultCenters.center_type = { value: defaultValues?.data?.center_type, flag: false }
      defaultCenters.pxl_manager = { value: defaultValues?.data?.data?.pxl_relationship_managers, flag: false }
      defaultCenters.site_manager = { value: defaultValues?.data?.data?.site_alliance_manager, flag: false }
      defaultCenters.department = { value: defaultValues?.data?.department, flag: false }
      defaultCenters.alliance_type = { value: defaultValues?.data?.alliance_type, flag: false }
      defaultCenters.alliance_member = { value: defaultValues?.data?.alliance_member, flag: false }
      defaultCenters.alliance_member_alliance_type = { value: defaultValues?.data?.alliance_member_alliance_type, flag: false }
      defaultCenters.alliance_member_id = { value: defaultValues?.data?.alliance_member_id, flag: false }
      defaultCenters.oms_loc_id = { value: defaultValues?.data?.oms_loc_id, flag: false }
      defaultCenters.oms_org_id = { value: defaultValues?.data?.oms_org_id, flag: false }
      defaultOthers = defaultValues?.data?.other_personnel?.map((itm) => {
        return {
          role: { old: itm.role, new: '', flag: false },
          role_at_site: { old: itm.role_at_site, new: '', flag: false },
          full_name: { old: itm.full_name, new: '', flag: false },
          id: { old: itm.id, new: '', flag: false },
          // first_name: { old: itm.first_name, new: '', flag: false },
          // last_name: { old: itm.last_name, new: '', flag: false },
          mobile_number: { old: itm.mobile_number, new: '', flag: false },
          fax: { old: itm.fax, new: '', flag: false },
          email: { old: itm.email, new: '', flag: false },
          same_location: { old: itm.same_location, new: '', flag: false },
          address: {
            address_line1: { old: itm?.address?.address_line1 || '', new: '', flag: false },
            address_line2: { old: itm?.address?.address_line2 || '', new: '', flag: false },
            country: { old: itm?.address?.country || '', new: '', flag: false },
            state: { old: itm?.address?.state || '', new: '', flag: false },
            zip: { old: itm?.address?.zip || '', new: '', flag: false },
            city: { old: itm?.address?.city || '', new: '', flag: false },
          },
        }
      })
      defaultCompliance.compliances = defaultValues?.data?.compliances || []
      defaultCDD.cdd_data = defaultValues?.data?.cdd_data || [{
        status: '',
        prevention_flag: '',
        date: '',
        comment: '',
      }]
      if (defaultValues?.data?.ccn_number !== '') {
        idAlready.push({
          key: 100,
          type: 'ccn_number',
          value: defaultValues?.data?.ccn_number,
          label: 'CCN Number',
        })
      }
      if (defaultValues?.data?.npi?.length) {
        for (let i = 0; i < defaultValues?.data?.npi.length; i += 1) {
          idAlready.push({
            key: 2000 + i,
            type: 'npi',
            value: defaultValues?.data?.npi[i],
            label: 'NPI Id',
          })
        }
      }
      if (defaultValues?.data?.pac_ids?.length) {
        for (let i = 0; i < defaultValues?.data?.pac_ids.length; i += 1) {
          idAlready.push({
            key: 3000 + i,
            type: 'pac_ids',
            value: defaultValues?.data?.pac_ids[i],
            label: 'PAC Id',
          })
        }
      }
      if (defaultValues?.data?.taxation_ids?.length) {
        for (let i = 0; i < defaultValues?.data?.taxation_ids.length; i += 1) {
          idAlready.push({
            key: 4000 + i,
            type: 'taxation_ids',
            value: defaultValues?.data?.taxation_ids[i],
            label: 'Taxation id',
          })
        }
      }
    }
  }

  const [generalInformation, setGeneralInformation] = useState({ ...defaultCenters, ...profile_responses })
  const [otherData, setOtherData] = useState([...defaultOthers])
  const [categoryData, setCategoryData] = useState({ ...profile_responses })
  const [generalInformationForm] = Form.useForm();
  const [generalInformationForm1] = Form.useForm();
  const [ids, setIds] = useState([...idAlready])
  const [otherDataForm] = Form.useForm();
  const [activekey, setActivekey] = useState(0)
  const [stateFlag, setStateFlag] = useState(true)

  const getCountryData = useSelector((store) => store.getCountryData);
  const centerTypeDataResponse = useSelector((store) => store.centerTypeDataResponse);
  const siteQuesResponse = useSelector((store) => store.siteQuesResponse);
  const addParentResponse = useSelector((state) => state.addParentResponse);
  const updateParentResponse = useSelector((state) => state.updateParentResponse);
  const [addCenter, setAddCenter] = useState(false)

  const [complianceIssues, setComplianceIssues] = useState(defaultCompliance.compliances || [])
  const [cddData, setCddData] = useState(defaultCDD.cdd_data || [{
    status: '',
    prevention_flag: '',
    date: '',
    comment: '',
  }])

  const [alertModal, setAlertModal] = useState(false)
  useEffect(() => {
    dispatch(getSiteQuesDataAction())
    dispatch(getCenterTypeDataAction({
      filter_key: 'center_type',
      type: parse(location.search).currentTab === 'parent_centers' ? 'parent' : 'center',
    }))
    dispatch(getCountryDataAction())
    dispatch(ListUsersAction({}))
    dispatch(getPiFilterDataAction())
  }, [])

  useEffect(() => {
    if (updateSiteResponse.flag && flag === 'editCenter') {
      setEditCenterModal(false)
      dispatch(getDeepdiveValuesAction({ id, dataset: 'centers' }));
    }
    if (updateParentResponse.flag && flag === 'editCenter') {
      setEditCenterModal(false)
      dispatch(getInstituteDeepdiveListAction({ id }));
    }
    if (createSiteResponse.flag && flag === 'fromMainCenter') {
      setEditModal(false)
      setAlertModal(false)
      dispatch(
        getDatasetListDataAction({
          query,
          dataset: parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab,
        }),
      );
      dispatch(addSiteResetAction())
    }

    if (addParentResponse.flag && flag === 'fromMainCenter') {
      setEditModal(false)
      setAlertModal(false)
      dispatch(
        getDatasetListDataAction({
          query,
          dataset: parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab,
        }),
      );
      dispatch(addSiteResetAction())
    }
    if (updateSiteResponse.error && updateSiteResponse.data.status === 409) {
      setAlertModal(true)
    }
    if (createSiteResponse.error && createSiteResponse.data.status === 409) {
      setAlertModal(true)
    }
    if (addParentResponse.error && addParentResponse.data.status === 409) {
      setAlertModal(true)
    }
    if (updateParentResponse.error && updateParentResponse.data.status === 409) {
      setAlertModal(true)
    }
  }, [JSON.stringify(updateSiteResponse), JSON.stringify(createSiteResponse), JSON.stringify(addParentResponse), JSON.stringify(updateParentResponse)])

  const transformStringToArray = (data) => {
    if (!data) {
      return [];
    }
    let temp = [];
    if (Array.isArray(data)) {
      temp = [...temp, ...data]
    } else {
      temp.push(data)
    }
    return temp
  }

  const processOtherData = (otherPersonnelData) => {
    const compData = otherPersonnelData.map((item) => {
      return {
        role: { old: item.role.old, new: item.role.new, flag: item.role.flag },
        role_at_site: { old: item.role_at_site.old, new: item.role_at_site.new, flag: item.role_at_site.flag },
        full_name: { old: item.full_name.old, new: item.full_name.new, flag: item.full_name.flag },
        id: { old: item.id.old, new: item.id.new, flag: item.id.flag },
        // first_name: { old: item.first_name.old, new: item.first_name.new, flag: item.first_name.flag },
        // last_name: { old: item.last_name.old, new: item.last_name.new, flag: item.last_name.flag },
        mobile_number: { old: item.mobile_number.old === 'null' ? [] : transformStringToArray(item.mobile_number.old), new: item.mobile_number.new === null ? [] : transformStringToArray(item.mobile_number.new), flag: item.mobile_number.flag },
        fax: { old: item.fax.old === 'null' ? [] : transformStringToArray(item.fax.old), new: item.fax.new === 'null' ? [] : transformStringToArray(item.fax.new), flag: item.fax.flag },
        email: { old: item.email.old === null ? [] : transformStringToArray(item.email.old), new: item.email.new === 'null' ? [] : transformStringToArray(item.email.new), flag: item.email.flag },
        same_location: { old: item.same_location.old === '' ? false : item.same_location.old, new: item.same_location.new === '' ? false : item.same_location.new, flag: item.same_location.flag },
        address: {
          address_line1: { old: item.address.address_line1.old || '', new: item.address.address_line1.new || '', flag: item.address.address_line1.flag },
          address_line2: { old: item.address.address_line2.old || '', new: item.address.address_line2.new || '', flag: item.address.address_line2.flag },
          country: { old: item.address.country.old || '', new: item.address.country.new || '', flag: item.address.country.flag },
          state: { old: item.address.state.old || '', new: item.address.state.new || '', flag: item.address.state.flag },
          zip: { old: item.address.zip.old || '', new: item.address.zip.new || '', flag: item.address.zip.flag },
          city: { old: item.address.city.old || '', new: item.address.city.new || '', flag: item.address.city.flag },
        },
      }
    })
    return compData
  }

  const getOtherPersonnelData = () => {
    const compData = otherData.map((item) => {
      return {
        role: item.role.new,
        role_at_site: item.role_at_site.new,
        full_name: item.full_name.new,
        id: item.id.new,
        // first_name: item.first_name.new,
        // last_name: item.last_name.new,
        mobile_number: item.mobile_number.new === null ? [] : transformStringToArray(item.mobile_number.new),
        fax: item.fax.new === null ? [] : transformStringToArray(item.fax.new),
        email: item.email.new === null ? [] : transformStringToArray(item.email.new),
        same_location: item.same_location.new,
        address: {
          address_line1: item.address.address_line1.new,
          address_line2: item.address.address_line2.new,
          country: item.address.country.new,
          state: item.address.state.new,
          zip: item.address.zip.new,
          city: item.address.city.new,
        },
      }
    })
    return compData
  }

  const getComplicanceData = () => {
    const compData = complianceIssues.map((item) => {
      return {
        fraud_misconduct_code: item.fraud_misconduct_code,
        compliance_issue_comment: item.compliance_issue_comment,
        compliance_issue_raised_dt: item.compliance_issue_raised_dt,
        compliance_issue_desc: item.compliance_issue_desc,
        no_trial_participation_flag: item.no_trial_participation_flag,
        review_status: item.review_status,
        valid_flag: item.valid_flag,
        review_date: item.review_date,
        reviewer_name: item.reviewer_name,
        reevaluation_comment: item.reevaluation_comment,
        compliance_issue_source: item.compliance_issue_source,
      }
    })
    return compData
  }

  const getIds = (type) => {
    const idsToReturn = ids.filter((item) => item.type === type)
    if (type === 'ccn_number' && idsToReturn.length) {
      return idsToReturn[0].value
    }
    if (type === 'ccn_number') {
      return ''
    }
    if (type === 'npi') {
      const npiIds = idsToReturn.map((item) => item.value.split(','));
      return npiIds.flat()
    }
    return idsToReturn.map((item) => item.value)
  }
  const onDoneClick = () => {
    if (countryForNpiNumber === 'United States' && warningFlag) {
      const data = ids.filter((itm) => itm.type === 'npi')
      if (data.length === 0) {
        setAlertModalForNpiID(true)
        setFlagForApiCall('')
      } else {
        setAlertModalForNpiID(false)
        setFlagForApiCall('')
        dispatch(getStateDataResetAction())
        // let compliance = {}
        // if (flag === 'editCenter') {
        //   if (complianceIssues.length > 0) {
        //     compliance = {
        //       old: false,
        //       new: true,
        //       flag: true,
        //     }
        //   } else {
        //     compliance = {
        //       old: false,
        //       new: false,
        //       flag: false,
        //     }
        //   }
        // }
        const final = {
          ...generalInformation, other_data: [...otherData], ...categoryData,
        }
        if (typeof final.alliance_member_id.value === 'object') {
          final.alliance_member_id = final.alliance_member_id.value.value
          final.alliance_member = final.alliance_member.value.value
        }
        const output = Object.entries(generalInformation).reduce((acc, [key, value]) => {
          if (
            Number.isInteger(Number(key))
          && typeof value === 'object'
          && 'value' in value
          ) {
            acc[key] = value.value;
          }
          return acc;
        }, {});

        const getProfRespData = () => {
          const pr = [];
          const pr_all = parse(location.search).currentTab === 'parent_centers' ? instituteDeepdiveList?.data?.data?.profile_responses : defaultValues?.data?.profile_responses
          const finalResp = {
            ...categoryData, ...output,
          }

          Object.keys(finalResp).forEach((k) => {
            const temp = {
              question_id: {
                old: Number(k),
                new: Number(k),
                flag: true,
              },
              text_response: {
                old: [''],
                new: Array.isArray(finalResp[k]) ? finalResp[k] : !finalResp[k] ? [] : [finalResp[k]],
                flag: true,
              },
            };
            const index = pr_all.findIndex(
              (item) => item.question_id.toString() === k.toString(),
            );
            if (index < 0) {
              pr.push(temp);
            } else {
              pr.push({
                question_id: {
                  old: Number(k),
                  new: Number(k),
                  flag: true,
                },
                text_response: {
                  old: pr_all[index].response,
                  new: Array.isArray(finalResp[k]) ? finalResp[k] : !finalResp[k] ? [] : [finalResp[k]],
                  flag: true,
                },
              });
            }
          });
          return pr
        }

        if (!setDefaultValues) {
          const temp = {
            name: final.centerName,
            solo_center_flag: final.solo_center_flag,
            address: {
              address_line1: final.address_line1,
              address_line2: final.address_line2,
              city: final.city,
              state: final.state,
              country: final.country,
              zip: final.postalcode,
            },
            parent_flag: false,
            fax: transformStringToArray(final.fax),
            email: transformStringToArray(final?.email),
            phone_country_code: '',
            url: final.url,
            phone_number: transformStringToArray(final.telephoneNo),
            mobile_number: transformStringToArray(final.mobileNo),
            other_personnel: getOtherPersonnelData(),
            type: 'SITE',
            center_type: final.center_type,
            ccn_number: getIds('ccn_number'),
            npi: getIds('npi'),
            pac_ids: getIds('pac_ids'),
            taxation_ids: getIds('taxation_ids'),
            pxl_relationship_managers: transformStringToArray(final.pxl_manager),
            site_alliance_manager: final.site_manager,
            department: final.department,
            alliance_member_id: final.alliance_member_id,
            alliance_member: final.alliance_member,
            alliance_type: final.alliance_type,
            oms_loc_id: final.oms_loc_id,
            oms_org_id: final.oms_org_id,
            compliances: getComplicanceData(),
            cdd_data: cddData,
            profile_responses: (() => {
              const responses = [];
              const finalKeys = Object.keys(final);
              finalKeys.forEach((key) => {
                if (!isNaN(parseInt(key, 10))) {
                  responses.push({
                    question_id: parseInt(key, 10),
                    int_response: [
                    ],
                    text_response: Array.isArray(final[key]) ? final[key] : [
                      final[key] || '',
                    ],
                  })
                }
              })
              return responses
            })(),
          }
          if (parse(location.search).currentTab === 'parent_centers') {
            dispatch(addParentAction({
              body: temp,
            }))
          } else {
            dispatch(createSiteAction({
              body: temp,
            }))
          }
        } else {
          const updateTemp = {
            name: {
              old: defaultCenters.centerName.value,
              new: final.centerName.value,
              flag: final.centerName.flag,
            },
            parent_flag: {
              old: false,
              new: false,
              flag: false,
            },
            url: {
              old: defaultCenters.url.value,
              new: final.url.value,
              flag: final.url.flag,
            },
            fax: {
              old: transformStringToArray(defaultCenters.fax.value),
              new: transformStringToArray(final.fax.value),
              flag: final.fax.flag,
            },
            solo_center_flag: {
              old: defaultCenters.solo_center_flag.value,
              new: final.solo_center_flag.value,
              flag: final.solo_center_flag.flag,
            },
            address: {
              address_line1: {
                old: defaultCenters.address_line1.value || '',
                new: final.address_line1.value || '',
                flag: final.address_line1.flag,
              },
              address_line2: {
                old: defaultCenters.address_line2.value || '',
                new: final.address_line2.value || '',
                flag: final.address_line2.flag,
              },

              city: {
                old: defaultCenters.city.value || '',
                new: final.city.value || '',
                flag: final.city.flag,
              },
              state: {
                old: defaultCenters?.state?.value || '',
                new: final?.state?.value || '',
                flag: final.state.flag,
              },
              country: {
                old: defaultCenters.country.value || '',
                new: final.country.value || '',
                flag: final.country.flag,
              },
              zip: {
                old: defaultCenters.postalcode.value || '',
                new: final.postalcode.value || '',
                flag: final.postalcode.flag,
              },
            },
            ccn_number: {
              old: defaultCenters.ccn_number?.data || '',
              new: getIds('ccn_number') || '',
              flag: true,
            },
            npi: {
              old: defaultCenters.npi?.data || [],
              new: getIds('npi') || [],
              flag: true,
            },
            pac_ids: {
              old: defaultCenters.pac_ids?.data || [],
              new: getIds('pac_ids') || [],
              flag: true,
            },
            taxation_ids: {
              old: defaultCenters.taxation_ids?.data || [],
              new: getIds('taxation_ids') || [],
              flag: true,
            },
            email: {
              old: transformStringToArray(defaultCenters?.email?.value),
              new: transformStringToArray(final?.email?.value),
              flag: final.email.flag,
            },
            pxl_center_sims_ids: {
              old: defaultCenters?.pxl_center_sims_ids.value,
              new: simsId !== '' ? [simsId] : [],
              flag: simsId !== '',
            },
            phone_number: {
              old: transformStringToArray(defaultCenters.telephoneNo.value),
              new: transformStringToArray(final.telephoneNo.value),
              flag: final.telephoneNo.flag,
            },
            mobile_number: {
              old: transformStringToArray(defaultCenters.mobileNo.value),
              new: transformStringToArray(final.mobileNo.value),
              flag: final.mobileNo.flag,
            },
            other_personnel: processOtherData(otherData),
            center_type: {
              old: defaultCenters.center_type.value,
              new: final.center_type.value,
              flag: final.center_type.flag,
            },
            department: {
              old: defaultCenters.department.value,
              new: final.department.value,
              flag: final.department.flag,
            },
            alliance_type: {
              old: defaultCenters.alliance_type?.value,
              new: final.alliance_type?.value,
              flag: final.alliance_type?.flag,
            },
            alliance_member_id: {
              old: defaultCenters.alliance_member_id.value,
              new: final.alliance_member_id?.value,
              flag: final.alliance_member_id?.flag,
            },
            oms_org_id: {
              old: defaultCenters.oms_org_id.value,
              new: final.oms_org_id?.value,
              flag: final.oms_org_id?.flag,
            },
            oms_loc_id: {
              old: defaultCenters.oms_loc_id.value,
              new: final.oms_loc_id?.value,
              flag: final.oms_loc_id?.flag,
            },

            pxl_relationship_managers: {
              old: transformStringToArray(defaultCenters.pxl_manager.value),
              new: transformStringToArray(final.pxl_manager.value),
              flag: final.pxl_manager?.flag,
            },
            site_alliance_manager: {
              old: defaultCenters.site_manager.value,
              new: final.site_manager?.value,
              flag: final.site_manager?.flag,
            },
            compliances: complianceIssues?.map((itm) => {
              return {
                no_trial_participation_flag: {
                  old: '',
                  new: itm.no_trial_participation_flag,
                  flag: true,
                },
                fraud_misconduct_code: {
                  old: '',
                  new: itm.fraud_misconduct_code,
                  flag: true,
                },
                compliance_issue_desc: {
                  old: '',
                  new: itm.compliance_issue_desc,
                  flag: true,
                },
                compliance_issue_source: {
                  old: '',
                  new: itm.compliance_issue_source,
                  flag: true,
                },
                compliance_issue_comment: {
                  old: '',
                  new: itm.compliance_issue_comment,
                  flag: true,
                },
                compliance_issue_raised_dt: {
                  old: '',
                  new: itm.compliance_issue_raised_dt,
                  flag: true,
                },
                compliance_issue_closed_dt: {
                  old: '',
                  new: itm.compliance_issue_closed_dt,
                  flag: true,
                },
                review_status: {
                  old: '',
                  new: itm.review_status,
                  flag: true,
                },
                valid_flag: {
                  old: undefined,
                  new: itm.valid_flag,
                  flag: true,
                },
                review_date: {
                  old: '',
                  new: itm.review_date,
                  flag: true,
                },
                reviewer_name: {
                  old: '',
                  new: itm.reviewer_name,
                  flag: true,
                },
                reevaluation_comment: {
                  old: '',
                  new: itm.reevaluation_comment,
                  flag: true,
                },
                delete_flag: {
                  old: false,
                  new: itm.delete_flag,
                  flag: true,
                },
              }
            }),
            cdd_data: cddData?.map((itm) => {
              return {
                status: {
                  old: '',
                  new: itm.status,
                  flag: true,
                },
                prevention_flag: {
                  old: '',
                  new: itm.prevention_flag,
                  flag: true,
                },
                date: {
                  old: '',
                  new: itm.date,
                  flag: true,
                },
                comment: {
                  old: '',
                  new: itm.comment,
                  flag: true,
                },
              }
            }),
            profile_responses: getProfRespData(),
          }
          if (parse(location.search).currentTab === 'parent_centers') {
            dispatch(updateParentAction({
              params: {
                site_id: instituteDeepdiveList?.data?.data?.id,
                direct_edit: true,
                duplicate_update: false,
              },
              body: updateTemp,
            }))
          } else {
            dispatch(updateSiteAction({
              params: {
                site_id: defaultValues?.data?.id,
                direct_edit: true,
                duplicate_update: false,
              },
              body: updateTemp,
            }))
          }
        }
        const params = {}
        setAnalytics('centerDeepDiveEdit', 'data', params)
      }
    } else {
      setFlagForApiCall('')
      dispatch(getStateDataResetAction())
      // let compliance = {}
      // if (flag === 'fromEditPI') {
      //   if (complianceIssues.length > 0) {
      //     compliance = {
      //       old: false,
      //       new: true,
      //       flag: true,
      //     }
      //   } else {
      //     compliance = {
      //       old: false,
      //       new: false,
      //       flag: false,
      //     }
      //   }
      // }
      const final = {
        ...generalInformation, other_data: [...otherData], ...categoryData,
      }
      if (typeof final?.alliance_member_id?.value === 'object') {
        final.alliance_member_id = final.alliance_member_id.value.value
        final.alliance_member = final.alliance_member.value.value
      }
      const output = Object.entries(generalInformation).reduce((acc, [key, value]) => {
        if (
          Number.isInteger(Number(key))
        && typeof value === 'object'
        && 'value' in value
        ) {
          acc[key] = value.value;
        }
        return acc;
      }, {});

      const getProfRespData = () => {
        const pr = [];
        const pr_all = parse(location.search).currentTab === 'parent_centers' ? instituteDeepdiveList?.data?.data?.profile_responses : defaultValues?.data?.profile_responses
        const finalResp = {
          ...categoryData, ...output,
        }

        Object.keys(finalResp).forEach((k) => {
          const temp = {
            question_id: {
              old: Number(k),
              new: Number(k),
              flag: true,
            },
            text_response: {
              old: [''],
              new: Array.isArray(finalResp[k]) ? finalResp[k] : !finalResp[k] ? [] : [finalResp[k]],
              flag: true,
            },
          };
          const index = pr_all.findIndex(
            (item) => item.question_id.toString() === k.toString(),
          );
          if (index < 0) {
            pr.push(temp);
          } else {
            pr.push({
              question_id: {
                old: Number(k),
                new: Number(k),
                flag: true,
              },
              text_response: {
                old: pr_all[index].response,
                new: Array.isArray(finalResp[k]) ? finalResp[k] : !finalResp[k] ? [] : [finalResp[k]],
                flag: true,
              },
            });
          }
        });
        return pr
      }

      if (!setDefaultValues) {
        const temp = {
          name: final.centerName,
          solo_center_flag: final.solo_center_flag,
          address: {
            address_line1: final.address_line1,
            address_line2: final.address_line2,
            city: final.city,
            state: final.state,
            country: final.country,
            zip: final.postalcode,
          },
          parent_flag: false,
          fax: transformStringToArray(final.fax),
          email: transformStringToArray(final?.email),
          phone_country_code: '',
          url: final.url,
          phone_number: transformStringToArray(final.telephoneNo),
          mobile_number: transformStringToArray(final.mobileNo),
          other_personnel: getOtherPersonnelData(),
          type: 'SITE',
          center_type: final.center_type,
          ccn_number: getIds('ccn_number'),
          npi: getIds('npi'),
          pac_ids: getIds('pac_ids'),
          taxation_ids: getIds('taxation_ids'),
          pxl_relationship_managers: transformStringToArray(final.pxl_manager),
          site_alliance_manager: final.site_manager,
          department: final.department,
          alliance_member_id: final.alliance_member_id,
          alliance_member: final.alliance_member,
          alliance_type: final.alliance_type,
          oms_loc_id: final.oms_loc_id,
          oms_org_id: final.oms_org_id,
          compliances: getComplicanceData(),
          cdd_data: cddData.length && cddData[0]?.status !== '' ? cddData : [],
          profile_responses: (() => {
            const responses = [];
            const finalKeys = Object.keys(final);
            finalKeys.forEach((key) => {
              if (!isNaN(parseInt(key, 10))) {
                responses.push({
                  question_id: parseInt(key, 10),
                  int_response: [
                  ],
                  text_response: Array.isArray(final[key]) ? final[key] : [
                    final[key] || '',
                  ],
                })
              }
            })
            return responses
          })(),
        }
        if (parse(location.search).currentTab === 'parent_centers') {
          dispatch(addParentAction({
            body: temp,
          }))
        } else {
          dispatch(createSiteAction({
            body: temp,
          }))
        }
      } else {
        const updateTemp = {
          name: {
            old: defaultCenters.centerName.value,
            new: final.centerName.value,
            flag: final.centerName.flag,
          },
          parent_flag: {
            old: false,
            new: false,
            flag: false,
          },
          url: {
            old: defaultCenters.url.value,
            new: final.url.value,
            flag: final.url.flag,
          },
          fax: {
            old: transformStringToArray(defaultCenters.fax.value),
            new: transformStringToArray(final.fax.value),
            flag: final.fax.flag,
          },
          solo_center_flag: {
            old: defaultCenters.solo_center_flag.value,
            new: final.solo_center_flag.value,
            flag: final.solo_center_flag.flag,
          },
          address: {
            address_line1: {
              old: defaultCenters.address_line1.value || '',
              new: final.address_line1.value || '',
              flag: final.address_line1.flag,
            },
            address_line2: {
              old: defaultCenters.address_line2.value || '',
              new: final.address_line2.value || '',
              flag: final.address_line2.flag,
            },

            city: {
              old: defaultCenters.city.value || '',
              new: final.city.value || '',
              flag: final.city.flag,
            },
            state: {
              old: defaultCenters?.state?.value || '',
              new: final?.state?.value || '',
              flag: final.state.flag,
            },
            country: {
              old: defaultCenters.country.value || '',
              new: final.country.value || '',
              flag: final.country.flag,
            },
            zip: {
              old: defaultCenters.postalcode.value || '',
              new: final.postalcode.value || '',
              flag: final.postalcode.flag,
            },
          },
          ccn_number: {
            old: defaultCenters.ccn_number?.data || '',
            new: getIds('ccn_number') || '',
            flag: true,
          },
          npi: {
            old: defaultCenters.npi?.data || [],
            new: getIds('npi') || [],
            flag: true,
          },
          pac_ids: {
            old: defaultCenters.pac_ids?.data || [],
            new: getIds('pac_ids') || [],
            flag: true,
          },
          taxation_ids: {
            old: defaultCenters.taxation_ids?.data || [],
            new: getIds('taxation_ids') || [],
            flag: true,
          },
          email: {
            old: transformStringToArray(defaultCenters?.email?.value),
            new: transformStringToArray(final?.email?.value),
            flag: final.email.flag,
          },
          pxl_center_sims_ids: {
            old: defaultCenters?.pxl_center_sims_ids.value,
            new: simsId !== '' ? [simsId] : [],
            flag: simsId !== '',
          },
          phone_number: {
            old: transformStringToArray(defaultCenters.telephoneNo.value),
            new: transformStringToArray(final.telephoneNo.value),
            flag: final.telephoneNo.flag,
          },
          mobile_number: {
            old: transformStringToArray(defaultCenters.mobileNo.value),
            new: transformStringToArray(final.mobileNo.value),
            flag: final.mobileNo.flag,
          },
          other_personnel: processOtherData(otherData),
          center_type: {
            old: defaultCenters.center_type.value,
            new: final.center_type.value,
            flag: final.center_type.flag,
          },
          department: {
            old: defaultCenters.department.value,
            new: final.department.value,
            flag: final.department.flag,
          },
          alliance_type: {
            old: defaultCenters.alliance_type?.value,
            new: final.alliance_type?.value,
            flag: final.alliance_type?.flag,
          },
          alliance_member_id: {
            old: defaultCenters.alliance_member_id.value,
            new: final.alliance_member_id?.value,
            flag: final.alliance_member_id?.flag,
          },
          oms_org_id: {
            old: defaultCenters.oms_org_id.value,
            new: final.oms_org_id?.value,
            flag: final.oms_org_id?.flag,
          },
          oms_loc_id: {
            old: defaultCenters.oms_loc_id.value,
            new: final.oms_loc_id?.value,
            flag: final.oms_loc_id?.flag,
          },

          pxl_relationship_managers: {
            old: transformStringToArray(defaultCenters.pxl_manager.value),
            new: transformStringToArray(final.pxl_manager.value),
            flag: final.pxl_manager?.flag,
          },
          compliances: complianceIssues?.map((itm) => {
            return {
              no_trial_participation_flag: {
                old: '',
                new: itm.no_trial_participation_flag,
                flag: true,
              },
              fraud_misconduct_code: {
                old: '',
                new: itm.fraud_misconduct_code,
                flag: true,
              },
              compliance_issue_desc: {
                old: '',
                new: itm.compliance_issue_desc,
                flag: true,
              },
              compliance_issue_source: {
                old: '',
                new: itm.compliance_issue_source,
                flag: true,
              },
              compliance_issue_comment: {
                old: '',
                new: itm.compliance_issue_comment,
                flag: true,
              },
              compliance_issue_raised_dt: {
                old: '',
                new: itm.compliance_issue_raised_dt,
                flag: true,
              },
              compliance_issue_closed_dt: {
                old: '',
                new: itm.compliance_issue_closed_dt,
                flag: true,
              },
              review_status: {
                old: '',
                new: itm.review_status,
                flag: true,
              },
              valid_flag: {
                old: undefined,
                new: itm.valid_flag,
                flag: true,
              },
              review_date: {
                old: '',
                new: itm.review_date,
                flag: true,
              },
              reviewer_name: {
                old: '',
                new: itm.reviewer_name,
                flag: true,
              },
              reevaluation_comment: {
                old: '',
                new: itm.reevaluation_comment,
                flag: true,
              },
              delete_flag: {
                old: false,
                new: itm.delete_flag,
                flag: true,
              },
            }
          }),
          cdd_data: cddData?.map((itm) => {
            return {
              status: {
                old: '',
                new: itm.status,
                flag: true,
              },
              prevention_flag: {
                old: '',
                new: itm.prevention_flag,
                flag: true,
              },
              date: {
                old: '',
                new: itm.date,
                flag: true,
              },
              comment: {
                old: '',
                new: itm.comment,
                flag: true,
              },
            }
          }),
          site_alliance_manager: {
            old: defaultCenters.site_manager.value,
            new: final.site_manager?.value,
            flag: final.site_manager?.flag,
          },
          profile_responses: getProfRespData(),
        }
        if (parse(location.search).currentTab === 'parent_centers') {
          dispatch(updateParentAction({
            params: {
              site_id: instituteDeepdiveList?.data?.data?.id,
              direct_edit: true,
              duplicate_update: false,
            },
            body: updateTemp,
          }))
        } else {
          dispatch(updateSiteAction({
            params: {
              site_id: defaultValues?.data?.id,
              direct_edit: true,
              duplicate_update: false,
            },
            body: updateTemp,
          }))
        }
      }
      const params = {}
      setAnalytics('centerDeepDiveEdit', 'data', params)
    }
  }

  const onSubmitClick = () => {
    dispatch(getStateDataResetAction())
    const final = {
      ...generalInformation, other_data: [...otherData], ...categoryData,
    }
    if (typeof final.alliance_member_id.value === 'object') {
      final.alliance_member_id = final.alliance_member_id.value.value
      final.alliance_member = final.alliance_member.value.value
    }

    // let compliance = {}
    // if (flag === 'fromEditPI') {
    //   if (complianceIssues.length > 0) {
    //     compliance = {
    //       old: false,
    //       new: true,
    //       flag: true,
    //     }
    //   } else {
    //     compliance = {
    //       old: false,
    //       new: false,
    //       flag: false,
    //     }
    //   }
    // }
    const output = Object.entries(generalInformation).reduce((acc, [key, value]) => {
      if (
        Number.isInteger(Number(key))
        && typeof value === 'object'
        && 'value' in value
      ) {
        acc[key] = value.value;
      }
      return acc;
    }, {});

    const getProfRespData = () => {
      const pr = [];
      const pr_all = parse(location.search).currentTab === 'parent_centers' ? instituteDeepdiveList?.data?.data?.profile_responses : defaultValues?.data?.profile_responses
      const finalResp = {
        ...categoryData, ...output,
      }

      Object.keys(finalResp).forEach((k) => {
        const temp = {
          question_id: {
            old: Number(k),
            new: Number(k),
            flag: true,
          },
          text_response: {
            old: [''],
            new: Array.isArray(finalResp[k]) ? finalResp[k] : !finalResp[k] ? [] : [finalResp[k]],
            flag: true,
          },
        };
        const index = pr_all.findIndex(
          (item) => item.question_id.toString() === k.toString(),
        );
        if (index < 0) {
          pr.push(temp);
        } else {
          pr.push({
            question_id: {
              old: Number(k),
              new: Number(k),
              flag: true,
            },
            text_response: {
              old: pr_all[index].response,
              new: finalResp[k] && Array.isArray(finalResp[k]) ? finalResp[k] : !finalResp[k] ? [] : [finalResp[k]],
              flag: true,
            },
          });
        }
      });
      return pr
    }

    if (!setDefaultValues) {
      const temp = {
        name: final.centerName,
        address: {
          address_line1: final.address_line1,
          address_line2: final.address_line2,
          city: final.city,
          state: final.state,
          country: final.country,
          zip: final.postalcode,
        },
        parent_flag: false,
        solo_center_flag: final.solo_center_flag,
        fax: transformStringToArray(final.fax),
        email: transformStringToArray(final?.email),
        phone_country_code: '',
        url: final.url,
        phone_number: transformStringToArray(final.telephoneNo),
        mobile_number: transformStringToArray(final.mobileNo),
        other_personnel: getOtherPersonnelData(),
        type: 'SITE',
        center_type: final.center_type,
        ccn_number: getIds('ccn_number'),
        npi: getIds('npi'),
        pac_ids: getIds('pac_ids'),
        taxation_ids: getIds('taxation_ids'),
        pxl_relationship_managers: transformStringToArray(final.pxl_manager),
        site_alliance_manager: final.site_manager,
        department: final.department,
        alliance_member_id: final.alliance_member_id,
        alliance_member: final.alliance_member,
        alliance_type: final.alliance_type,
        oms_loc_id: final.oms_loc_id,
        oms_org_id: final.oms_org_id,
        compliances: getComplicanceData(),
        cdd_data: cddData.length && cddData[0]?.status !== '' ? cddData : [],
        profile_responses: (() => {
          const responses = [];
          const finalKeys = Object.keys(final);
          finalKeys.forEach((key) => {
            if (!isNaN(parseInt(key, 10))) {
              responses.push({
                question_id: parseInt(key, 10),
                int_response: [
                ],
                text_response: Array.isArray(final[key]) ? final[key] : [
                  final[key] || '',
                ],
              })
            }
          })
          return responses
        })(),
      }
      if (parse(location.search).currentTab === 'parent_centers') {
        dispatch(addParentAction({
          body: temp,
        }))
      } else {
        dispatch(createSiteAction({
          body: temp,
        }))
      }
    } else {
      const updateTemp = {
        name: {
          old: defaultCenters.centerName.value,
          new: final.centerName.value,
          flag: final.centerName.flag,
        },
        parent_flag: {
          old: false,
          new: false,
          flag: false,
        },
        url: {
          old: defaultCenters.url.value,
          new: final.url.value,
          flag: final.url.flag,
        },
        fax: {
          old: transformStringToArray(defaultCenters.fax.value),
          new: transformStringToArray(final.fax.value),
          flag: final.fax.flag,
        },
        solo_center_flag: {
          old: defaultCenters.solo_center_flag.value,
          new: final.solo_center_flag.value,
          flag: final.solo_center_flag.flag,
        },
        address: {
          address_line1: {
            old: defaultCenters.address_line1.value || '',
            new: final.address_line1.value || '',
            flag: final.address_line1.flag,
          },
          address_line2: {
            old: defaultCenters.address_line2.value || '',
            new: final.address_line2.value || '',
            flag: final.address_line2.flag,
          },

          city: {
            old: defaultCenters.city.value || '',
            new: final.city.value || '',
            flag: final.city.flag,
          },
          state: {
            old: defaultCenters?.state?.value || '',
            new: final?.state?.value || '',
            flag: final.state.flag,
          },
          country: {
            old: defaultCenters.country.value || '',
            new: final.country.value || '',
            flag: final.country.flag,
          },
          zip: {
            old: defaultCenters.postalcode.value || '',
            new: final.postalcode.value || '',
            flag: final.postalcode.flag,
          },
        },
        ccn_number: {
          old: defaultCenters.ccn_number?.data || '',
          new: getIds('ccn_number') || '',
          flag: true,
        },
        npi: {
          old: defaultCenters.npi?.data || [],
          new: getIds('npi') || [],
          flag: true,
        },
        pac_ids: {
          old: defaultCenters.pac_ids?.data || [],
          new: getIds('pac_ids') || [],
          flag: true,
        },
        taxation_ids: {
          old: defaultCenters.taxation_ids?.data || [],
          new: getIds('taxation_ids') || [],
          flag: true,
        },
        email: {
          old: transformStringToArray(defaultCenters?.email?.value),
          new: transformStringToArray(final?.email?.value),
          flag: final.email.flag,
        },
        pxl_center_sims_ids: {
          old: defaultCenters?.pxl_center_sims_ids.value,
          new: simsId !== '' ? [simsId] : [],
          flag: simsId !== '',
        },
        phone_number: {
          old: transformStringToArray(defaultCenters.telephoneNo.value),
          new: transformStringToArray(final.telephoneNo.value),
          flag: final.telephoneNo.flag,
        },
        mobile_number: {
          old: transformStringToArray(defaultCenters.mobileNo.value),
          new: transformStringToArray(final.mobileNo.value),
          flag: final.mobileNo.flag,
        },
        other_personnel: processOtherData(otherData),
        center_type: {
          old: defaultCenters.center_type.value,
          new: final.center_type.value,
          flag: final.center_type.flag,
        },
        department: {
          old: defaultCenters.department.value,
          new: final.department.value,
          flag: final.department.flag,
        },
        alliance_type: {
          old: defaultCenters.alliance_type?.value,
          new: final.alliance_type?.value,
          flag: final.alliance_type?.flag,
        },
        alliance_member_id: {
          old: defaultCenters.alliance_member_id.value,
          new: final.alliance_member_id?.value,
          flag: final.alliance_member_id?.flag,
        },
        oms_org_id: {
          old: defaultCenters.oms_org_id.value,
          new: final.oms_org_id?.value,
          flag: final.oms_org_id?.flag,
        },
        oms_loc_id: {
          old: defaultCenters.oms_loc_id.value,
          new: final.oms_loc_id?.value,
          flag: final.oms_loc_id?.flag,
        },
        compliances: complianceIssues?.map((itm) => {
          return {
            no_trial_participation_flag: {
              old: '',
              new: itm.no_trial_participation_flag,
              flag: true,
            },
            fraud_misconduct_code: {
              old: '',
              new: itm.fraud_misconduct_code,
              flag: true,
            },
            compliance_issue_desc: {
              old: '',
              new: itm.compliance_issue_desc,
              flag: true,
            },
            compliance_issue_comment: {
              old: '',
              new: itm.compliance_issue_comment,
              flag: true,
            },
            compliance_issue_source: {
              old: '',
              new: itm.compliance_issue_source,
              flag: true,
            },
            compliance_issue_raised_dt: {
              old: '',
              new: itm.compliance_issue_raised_dt,
              flag: true,
            },
            compliance_issue_closed_dt: {
              old: '',
              new: itm.compliance_issue_closed_dt,
              flag: true,
            },
            review_status: {
              old: '',
              new: itm.review_status,
              flag: true,
            },
            valid_flag: {
              old: undefined,
              new: itm.valid_flag,
              flag: true,
            },
            review_date: {
              old: '',
              new: itm.review_date,
              flag: true,
            },
            reviewer_name: {
              old: '',
              new: itm.reviewer_name,
              flag: true,
            },
            reevaluation_comment: {
              old: '',
              new: itm.reevaluation_comment,
              flag: true,
            },
            delete_flag: {
              old: false,
              new: itm.delete_flag,
              flag: true,
            },
          }
        }),
        cdd_data: cddData?.map((itm) => {
          return {
            status: {
              old: '',
              new: itm.status,
              flag: true,
            },
            prevention_flag: {
              old: '',
              new: itm.prevention_flag,
              flag: true,
            },
            date: {
              old: '',
              new: itm.date,
              flag: true,
            },
            comment: {
              old: '',
              new: itm.comment,
              flag: true,
            },
          }
        }),

        pxl_relationship_managers: {
          old: transformStringToArray(defaultCenters.pxl_manager.value),
          new: transformStringToArray(final.pxl_manager.value),
          flag: final.pxl_manager?.flag,
        },
        site_alliance_manager: {
          old: defaultCenters.site_manager.value,
          new: final.site_manager?.value,
          flag: final.site_manager?.flag,
        },
        profile_responses: getProfRespData(),
      }
      if (parse(location.search).currentTab === 'parent_centers') {
        dispatch(updateParentAction({
          params: {
            site_id: instituteDeepdiveList?.data?.data?.id,
            direct_edit: true,
            duplicate_update: false,
          },
          body: updateTemp,
        }))
      } else {
        dispatch(updateSiteAction({
          params: {
            site_id: defaultValues?.data?.id,
            direct_edit: true,
            duplicate_update: false,
          },
          body: updateTemp,
        }))
      }
    }
    const params = {}
    setAnalytics('centerDeepDiveEdit', 'data', params)
  }

  const onSaveAndAddMoreClick = (setChildOtherData, childOtherData, form, eflag, idx) => {
    otherDataForm.resetFields();
    let defaultOther
    if (flag === 'fromMainCenter') {
      defaultOther = {
        role: '', role_at_site: '', name_of_department: '', full_name: '', mobile_number: [], fax: [], email: [], same_location: '',
      }
    } else {
      defaultOther = {
        role: { value: '', flag: false },
        role_at_site: { value: '', flag: false },
        name_of_department: { value: '', flag: false },
        full_name: { value: '', flag: false },
        id: { value: '', flag: false },
        // first_name: { value: '', flag: false },
        // last_name: { value: '', flag: false },
        mobile_number: { value: [], flag: false },
        fax: { value: [], flag: false },
        email: { value: [], flag: false },
        same_location: { value: '', flag: false },
      }
    }
    if (eflag === 'saveEdit') {
      setOtherData((oldState) => {
        const temp = oldState
        temp[idx] = childOtherData
        return temp
      })
    } else {
      setOtherData((oldState) => {
        return [...oldState, childOtherData]
      })
    }
    setChildOtherData(defaultOther)
    form.resetFields();
  }

  function addCategory(groupedData, item) {
    if (parse(location.search).currentTab === 'parent_centers') {
      if (!groupedData[item.parent_category]) {
        groupedData[item.parent_category] = {
          items: [],
          hasSubcategories: false,
        };
      }
      groupedData[item.parent_category].items.push(item);
    } else {
      if (!groupedData[item.child_category]) {
        groupedData[item.child_category] = {
          items: [],
          hasSubcategories: false,
        };
      }
      groupedData[item.child_category].items.push(item);
    }
  }

  function groupByCategory(data) {
    const groupedData = {};
    if (parse(location.search).currentTab === 'parent_centers') {
      data.forEach((item) => {
        let condition = false
        if (flag === 'fromMainCenter') {
          condition = !groupedData[item.parent_category] && flag !== 'fromMainCenter' && (!item?.applicable.includes('child') || !item?.applicable.includes('parent'));
        }

        if (flag === 'editCenter') {
          condition = !groupedData[item.parent_category] && flag !== 'editCenter' && (!item?.applicable.includes('child') || !item?.applicable.includes('parent'));
        }
        if (item.parent_category === 'Other Key Personnel' || item.parent_category === 'General Information' || (condition)
          || (condition && parse(location.search).currentTab !== 'parent_centers')) {
          return {}
        }

        if ((flag === 'fromMainCenter' || flag === 'editCenter') && parse(location.search).currentTab !== 'parent_centers' && item?.applicable.includes('child')) {
          addCategory(groupedData, item)
        } else if ((flag === 'fromMainCenter' || flag === 'editCenter') && parse(location.search).currentTab === 'parent_centers' && item?.applicable.includes('parent')) {
          addCategory(groupedData, item)
        } else {
          return {}
        }

        if (item.parent_sub_category && item.parent_sub_category.length > 0 && item.parent_sub_category !== item.parent_category) {
          groupedData[item.parent_category].hasSubcategories = true;
        }
        return {}
      });
    } else {
      data.forEach((item) => {
        let condition = false
        if (flag === 'fromMainCenter') {
          condition = !groupedData[item.child_category] && flag !== 'fromMainCenter' && (!item?.applicable.includes('child') || !item?.applicable.includes('parent'));
        }

        if (flag === 'editCenter') {
          condition = !groupedData[item.child_category] && flag !== 'editCenter' && (!item?.applicable.includes('child') || !item?.applicable.includes('parent'));
        }
        if (item.child_category === 'Other Key Personnel' || (condition)
          || (condition && parse(location.search).currentTab !== 'parent_centers')) {
          return {}
        }

        if ((flag === 'fromMainCenter' || flag === 'editCenter') && parse(location.search).currentTab !== 'parent_centers' && item?.applicable.includes('child')) {
          addCategory(groupedData, item)
        } else if ((flag === 'fromMainCenter' || flag === 'editCenter') && parse(location.search).currentTab === 'parent_centers' && item?.applicable.includes('parent')) {
          addCategory(groupedData, item)
        } else {
          return {}
        }

        if (item.child_sub_category && item.child_sub_category.length > 0 && item.child_sub_category !== item.child_category) {
          groupedData[item.child_category].hasSubcategories = true;
        }
        return {}
      });
    }

    return groupedData;
  }

  const generateDynamicSteps = (groupedData) => {
    return Object.keys(groupedData).map((category) => {
      if (groupedData[category].hasSubcategories && groupedData[category].items.length > 0) {
        return {
          title: category,
          content: (
            <Facilities
              data={{
                categoryData,
                setCategoryData,
                flag,
                questionsData: groupedData[category].items,
              }}
              category={category}
              activekey={activekey}
              setActivekey={setActivekey}
            />
          ),
        };
      }
      if (category === 'Quality') {
        return {
          title: category,
          content: (
            <CenterComplianceIssue
              data={{
                complianceIssues,
                setComplianceIssues,
                cddData,
                setCddData,
                flag,
              }}
            />
          ),
        };
      }

      return {
        title: category,
        content: (
          <PatientAccess
            data={{
              categoryData,
              setCategoryData,
              flag,
              questionsData: groupedData[category].items,
            }}
            category={category}
          />
        ),
      };
    });
  };

  const generateSteps = () => {
    let dynamicSteps = []
    if (siteQuesResponse && siteQuesResponse?.data?.data && siteQuesResponse?.data?.data.length > 0) {
      const groupedData = groupByCategory(siteQuesResponse?.data?.data)
      if (flag === 'editCenter' && getCookie('permissions').includes('super_admin')) {
        dynamicSteps = generateDynamicSteps(groupedData);
      } else if (flag === 'fromMainCenter' && getCookie('permissions').includes('super_admin')) {
        dynamicSteps = generateDynamicSteps(groupedData);
      } else {
        delete groupedData.Quality;
        dynamicSteps = generateDynamicSteps(groupedData);
      }
    }
    const staticSteps = [
      {
        title: 'General Information',
        content: <GeneralInformation data={{
          generalInformation,
          setGeneralInformation,
          generalInformationForm,
          getCountryData,
          centerTypeDataResponse,
          generalInformationForm1,
          IsDropDownOpen: addCenter,
          SetDropDownOpen: setAddCenter,
          IsOtherDropDownOpen: addOtherCenter,
          SetOtherDropDownOpen: setAddOtherCenter,
          ids,
          setIds,
          flag,
          setStateFlag,
          stateFlag,
          setNoParent,
          setIsSimsIdVerified,
          simsId,
          setSimsId,
          setCountryForNpiNumber,
          countryForNpiNumber,
          setWarningFlag,
          noParent,
        }}
        />
        ,
      },
      {
        title: 'Other Key Personnel',
        content: <SiteOtherPersonnel data={{
          otherData, setOtherData, flag, onSaveAndAddMoreClick, otherDataForm,
        }}
        // content: <OtherKeyPersonnelAddNew data={{
        //   otherData, setOtherData, flag, onSaveAndAddMoreClick, otherDataForm,
        // }}
        />,
      },
    ]
    return [...staticSteps, ...dynamicSteps]
  }

  const steps = generateSteps()

  const { Step } = Steps;

  const [isFormValidated, setIsFormValidated] = useState(false);
  const isDisable = () => {
    if (isFormValidated) {
      return true;
    }

    let firstStep
    if (hasPermissionForAction('edit_sims_id') && flag === 'editCenter' && !isSimsIdVerified) {
      return true
    }
    if (stateFlag) {
      firstStep = !configRequired[0]?.every((itm) => {
        if (Array.isArray(generalInformation[itm])) {
          return generalInformation[itm].length > 0
        }
        if (generalInformation[itm]?.value === '') {
          return false
        }
        return generalInformation[itm]
      })
    } else {
      firstStep = !configMandatory[0]?.every((itm) => {
        if (Array.isArray(generalInformation[itm])) {
          return generalInformation[itm].length > 0
        }
        return !!generalInformation[itm]
      })
    }

    if (current === 0) {
      return firstStep || noParent
    }
    if (current === 7) {
      return firstStep || noParent
    }

    return false;
  }

  const [current, setCurrent] = useState(0);

  const next = () => {
    if (current === 2 && activekey < 9 && parse(location.search).currentTab !== 'parent_centers') {
      setActivekey((k) => parseInt(k, 10) + 1)
    } else if (countryForNpiNumber === 'United States' && warningFlag) {
      const data = ids.filter((itm) => itm.type === 'npi')
      if (data.length === 0) {
        setAlertModalForNpiID(true)
        setActivekey(0)
        setCurrent(0)
        setJumpToPage(current + 1)
        setFlagForApiCall('next')
      } else {
        setAlertModalForNpiID(false)
        setActivekey(0)
        setCurrent(current + 1);
        setFlagForApiCall('')
      }
    } else {
      setFlagForApiCall('')
      setAlertModalForNpiID(false)
      setActivekey(0)
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    if (current === 2 && activekey !== 0 && activekey <= 9 && parse(location.search).currentTab !== 'parent_centers') {
      setActivekey((k) => parseInt(k, 10) - 1)
    } else {
      setActivekey(0)
      setCurrent(current - 1);
    }
  };
  const onResetClick = () => {
    if (flag === 'editCenter') {
      switch (current) {
        case 0:
          setGeneralInformation({
            centerName: defaultValues?.data?.name,
            telephoneNo: defaultValues?.data?.phone_number,
            mobileNo: defaultValues?.data?.mobile_number,
            email: defaultValues?.data?.email,
            address_line1: defaultValues?.data?.address?.address_line1,
            address_line2: defaultValues?.data?.address?.address_line2,
            city: defaultValues?.data?.address?.city,
            state: defaultValues?.data?.address?.state,
            country: defaultValues?.data?.address?.country,
            postalcode: defaultValues?.data?.address?.zip,
            parent_flag: defaultValues?.data?.parent_flag,
            department: defaultValues?.data?.department,
          })
          generalInformationForm.resetFields();
          break;
        case 1:
        case 2:
        case 3:
        case 4:
          setCategoryData(profile_responses)
          break;
        case 9: setComplianceIssues(defaultCompliance.compliances || []);
          setCddData([])
          break;
        default:
      }
    } else {
      switch (current) {
        case 0:
          setGeneralInformation(blankGeneralInformation)
          generalInformationForm.resetFields();
          break;
        case 9: setComplianceIssues(defaultCompliance.compliances || []);
          break;
        default:
      }
    }
  }

  const onProceedBtn = () => {
    if (flagForApiCall === 'next' || flagForApiCall === 'steps') {
      setCurrent(jumpToPage)
      setWarningFlag(false)
      setAlertModalForNpiID(false);
      setFlagForApiCall('')
    } else {
      onSubmitClick()
      setWarningFlag(false)
      setAlertModalForNpiID(false);
      setFlagForApiCall('')
    }
  }

  const onStepsChange = (value) => {
    if (countryForNpiNumber === 'United States' && warningFlag) {
      const data = ids.filter((itm) => itm.type === 'npi')
      if (data.length === 0) {
        setAlertModalForNpiID(true)
        setCurrent(0);
        setJumpToPage(value)
        setFlagForApiCall('steps')
      } else {
        setFlagForApiCall('')
        setAlertModalForNpiID(false)
        setCurrent(value);
      }
    } else {
      setFlagForApiCall('')
      setCurrent(value)
    }
  };

  const onExistPiYesClick = () => {
    const final = { ...generalInformation, other_data: [...otherData], ...categoryData }
    if (typeof final.alliance_member_id.value === 'object') {
      final.alliance_member_id = final.alliance_member_id.value.value
      final.alliance_member = final.alliance_member.value.value
    }
    const getProfRespData = () => {
      const pr = [];
      const pr_all = defaultValues?.data?.profile_responses;
      const finalResp = categoryData
      Object.keys(finalResp).forEach((k) => {
        const temp = {
          question_id: {
            old: Number(k),
            new: Number(k),
            flag: true,
          },
          text_response: {
            old: [''],
            new: Array.isArray(finalResp[k]) ? finalResp[k] : !finalResp[k] ? [] : [finalResp[k]],
            flag: true,
          },
        };
        const index = pr_all.findIndex(
          (item) => item.question_id.toString() === k.toString(),
        );
        if (index < 0) {
          pr.push(temp);
        } else {
          pr.push({
            question_id: {
              old: Number(k),
              new: Number(k),
              flag: true,
            },
            text_response: {
              old: pr_all[index].response,
              new: Array.isArray(final[k]) ? final[k] : !final[k] ? [] : [final[k]],
              flag: true,
            },
          });
        }
      });
      return pr
    }
    if (!setDefaultValues) {
      const temp = {
        name: final.centerName,
        address: {
          address_line1: final?.address_line1,
          address_line2: final?.address_line2,
          city: final.city,
          state: final.state,
          country: final.country,
          zip: final.postalcode,
        },
        email: transformStringToArray(final?.email),
        solo_center_flag: final?.solo_center_flag,
        phone_country_code: '',
        parent_flag: false,
        url: final.url,
        fax: transformStringToArray(final.fax),
        phone_number: transformStringToArray(final.telephoneNo),
        mobile_number: transformStringToArray(final.mobileNo),
        other_personnel: getOtherPersonnelData(),
        type: 'SITE',
        ccn_number: getIds('ccn_number'),
        npi: getIds('npi'),
        pac_ids: getIds('pac_ids'),
        taxation_ids: getIds('taxation_ids'),
        alliance_member_id: final.alliance_member_id,
        alliance_member: final.alliance_member,
        alliance_type: final.alliance_type,
        oms_loc_id: final.oms_loc_id,
        oms_org_id: final.oms_org_id,
        center_type: final.center_type,
        pxl_relationship_managers: transformStringToArray(final.pxl_manager),
        site_alliance_manager: final.site_manager,
        department: final.department,
        compliances: getComplicanceData(),
        cdd_data: cddData.length && cddData[0]?.status !== '' ? cddData : [],
        profile_responses: (() => {
          const responses = [];
          const finalKeys = Object.keys(final);
          finalKeys.forEach((key) => {
            if (!isNaN(parseInt(key, 10))) {
              responses.push({
                question_id: parseInt(key, 10),
                int_response: [
                ],
                text_response: Array.isArray(final[key]) ? final[key]

                  : [
                    final[key] || '',
                  ],
              })
            }
          })
          return responses
        })(),
      }

      dispatch(createSiteAction({
        body: temp,
        params: {
          duplicate_update: true,
        },
      }))
    } else {
      const updateTemp = {
        name: {
          old: defaultCenters.centerName.value,
          new: final.centerName.value,
          flag: final.centerName.flag,
        },
        solo_center_flag: {
          old: defaultCenters.solo_center_flag.value,
          new: final.solo_center_flag.value,
          flag: final.solo_center_flag.flag,
        },
        address: {
          address_line1: {
            old: defaultCenters?.address_line1.value || '',
            new: final?.address_line1.value || '',
            flag: final?.address_line1.flag,
          },
          address_line2: {
            old: defaultCenters.address_line2.value || '',
            new: final.address_line2.value || '',
            flag: final.address_line2.flag,
          },

          city: {
            old: defaultCenters.city.value || '',
            new: final.city.value || '',
            flag: final.city.flag,
          },
          state: {
            old: defaultCenters.state.value || '',
            new: final.country.value || '',
            flag: final.country.flag,
          },
          country: {
            old: defaultCenters.country.value || '',
            new: final.country.value || '',
            flag: final.country.flag,
          },
          zip: {
            old: defaultCenters.postalcode.value || '',
            new: final.postalcode.value || '',
            flag: final.postalcode.flag,
          },
        },
        email: {
          old: transformStringToArray(defaultCenters?.email?.value),
          new: transformStringToArray(final?.email?.value),
          flag: final.email.flag,
        },
        ccn_number: {
          old: defaultCenters.ccn_number?.data || '',
          new: getIds('ccn_number') || '',
          flag: true,
        },
        npi: {
          old: defaultCenters.npi?.data || [],
          new: getIds('npi') || [],
          flag: true,
        },
        pac_ids: {
          old: defaultCenters.pac_ids?.data || [],
          new: getIds('pac_ids') || [],
          flag: true,
        },
        taxation_ids: {
          old: defaultCenters.taxation_ids?.data || [],
          new: getIds('taxation_ids') || [],
          flag: true,
        },
        url: {
          old: defaultCenters.url.value,
          new: final.url.value,
          flag: final.url.flag,
        },
        fax: {
          old: transformStringToArray(defaultCenters.fax.value),
          new: transformStringToArray(final.fax.value),
          flag: final.fax.flag,
        },
        phone_number: {
          old: transformStringToArray(defaultCenters.telephoneNo.value),
          new: transformStringToArray(final.telephoneNo.value),
          flag: final.telephoneNo.flag,
        },
        mobile_number: {
          old: transformStringToArray(defaultCenters.mobileNo.value),
          new: transformStringToArray(final.mobileNo.value),
          flag: final.mobileNo.flag,
        },
        other_personnel: processOtherData(final.other_data),
        center_type: {
          old: defaultCenters.center_type.value,
          new: final.center_type.value,
          flag: final.center_type.flag,
        },
        pxl_relationship_managers: {
          old: transformStringToArray(defaultCenters.pxl_manager.value),
          new: transformStringToArray(final.pxl_manager.value),
          flag: final.pxl_manager.flag,
        },
        site_alliance_manager: {
          old: defaultCenters.site_manager.value,
          new: final.site_manager.value,
          flag: final.site_manager.flag,
        },
        department: {
          old: defaultCenters.department.value,
          new: final.department.value,
          flag: final.department.flag,
        },
        alliance_type: {
          old: defaultCenters.alliance_type?.value,
          new: final.alliance_type?.value,
          flag: final.alliance_type?.flag,
        },
        alliance_member_id: {
          old: defaultCenters.alliance_member_id.value,
          new: final.alliance_member_id?.value,
          flag: final.alliance_member_id?.flag,
        },
        oms_org_id: {
          old: defaultCenters.oms_org_id.value,
          new: final.oms_org_id?.value,
          flag: final.oms_org_id?.flag,
        },
        oms_loc_id: {
          old: defaultCenters.oms_loc_id.value,
          new: final.oms_loc_id?.value,
          flag: final.oms_loc_id?.flag,
        },
        compliances: complianceIssues?.map((itm) => {
          return {
            no_trial_participation_flag: {
              old: '',
              new: itm.no_trial_participation_flag,
              flag: true,
            },
            fraud_misconduct_code: {
              old: '',
              new: itm.fraud_misconduct_code,
              flag: true,
            },
            compliance_issue_desc: {
              old: '',
              new: itm.compliance_issue_desc,
              flag: true,
            },
            compliance_issue_source: {
              old: '',
              new: itm.compliance_issue_source,
              flag: true,
            },
            compliance_issue_comment: {
              old: '',
              new: itm.compliance_issue_comment,
              flag: true,
            },
            compliance_issue_raised_dt: {
              old: '',
              new: itm.compliance_issue_raised_dt,
              flag: true,
            },
            compliance_issue_closed_dt: {
              old: '',
              new: itm.compliance_issue_closed_dt,
              flag: true,
            },
            review_status: {
              old: '',
              new: itm.review_status,
              flag: true,
            },
            valid_flag: {
              old: undefined,
              new: itm.valid_flag,
              flag: true,
            },
            review_date: {
              old: '',
              new: itm.review_date,
              flag: true,
            },
            reviewer_name: {
              old: '',
              new: itm.reviewer_name,
              flag: true,
            },
            reevaluation_comment: {
              old: '',
              new: itm.reevaluation_comment,
              flag: true,
            },
            delete_flag: {
              old: false,
              new: itm.delete_flag,
              flag: true,
            },
          }
        }),
        cdd_data: cddData?.map((itm) => {
          return {
            status: {
              old: '',
              new: itm.status,
              flag: true,
            },
            prevention_flag: {
              old: '',
              new: itm.prevention_flag,
              flag: true,
            },
            date: {
              old: '',
              new: itm.date,
              flag: true,
            },
            comment: {
              old: '',
              new: itm.comment,
              flag: true,
            },
          }
        }),

        parent_flag: {
          old: false,
          new: false,
          flag: false,
        },
        profile_responses: getProfRespData(),
      }
      dispatch(updateSiteAction({
        params: {
          site_id: defaultValues?.data?.id,
          direct_edit: true,
          duplicate_update: true,
        },
        body: updateTemp,
      }))
    }
    const params = {}
    setAnalytics('centerDeepDiveEdit', 'data', params)
  }

  const onExistPiNoClick = () => {
    if (flag === 'editCenter') {
      setEditCenterModal(false)
    } else {
      setEditModal(false)
    }
    setAlertModal(false)
    window.location.reload()
  }

  return (
    <div className="header-wrap">
      <Loader loading={updateSiteResponse.loading || createSiteResponse.loading || addParentResponse.loading || updateParentResponse.loading}>
        <Form.Provider
          onFormChange={(name, { forms }) => {
            const validated = Object.values(forms).every((form) => {
              return form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
            })
            setIsFormValidated(validated)
          }}
        >
          <Steps current={current} onChange={onStepsChange}>
            {steps?.map((item) => {
              return (
                <Step
                  key={item.title}
                  title={item.title}
                  disabled={isDisable()}
                />
              )
            })}
          </Steps>
          <div className={((flag === 'editCenter' || flag === 'fromMainCenter') && getCookie('permissions').includes('super_admin') && current === 9) ? 'steps-content compliance'
            : 'steps-content'}
          >
            <div className="investigator-page-wrapper">
              {steps[current].content}
            </div>
          </div>
          <div className="steps-action">
            {(flag === 'editCenter' && defaultValues.data.parent_flag === false && current === 7)
              || (flag === 'editCenter' && defaultValues.data.parent_flag === true && current === 10)
              || (flag === 'fromMainCenter' && current === 7)
              ? null : <Button onClick={onDoneClick} disabled={isDisable()}>Save and exit</Button>}
            <Button onClick={onResetClick}>Reset</Button>
            {(current !== 0 && current < steps.length - 1) && (
              <Button onClick={() => next()}>
                Skip
              </Button>
            )}
            {current > 0 && (
              <Button
                style={{
                  margin: '0 8px',
                }}
                onClick={() => prev()}
                type="primary"
              >
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => next()}
                disabled={isDisable()}
              >
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" onClick={onDoneClick} disabled={isDisable()}>
                Done
              </Button>
            )}
          </div>
        </Form.Provider>

        {alertModal && (
          <Modal
            title="SITE probably exists in LandSCAPE"
            open={alertModal}
            onCancel={() => {
              setAlertModal(false);
              // setIsFormValidated(isFormValidated)
            }}
            className="modal-wrapper add-investigator-modal mark-valid-invalid-pi-modal"
            maskClosable={false}
            destroyOnClose
            footer={null}
          >
            Another SITE exists in LandSCAPE with same name. Do you still want to continue with your changes?
            <Button onClick={onExistPiYesClick}> Yes </Button>
            <Button onClick={onExistPiNoClick}> No </Button>
          </Modal>
        )}
        {
          alertModalForNpiID && (
            <Modal
              style={{ marginTop: '81px', width: '100%' }}
              title="Confirmation"
              open={alertModalForNpiID}
              className="del-modal-wrapper conf-modal-wrapper"
              onCancel={() => {
                setAlertModalForNpiID(false);
                setWarningFlag(true)
                setFlagForApiCall('')
              }}
              destroyOnClose
              footer={null}
            >
              <div className="conf-desc"> You have not provided NPI, are you sure you want to proceed?</div>
              <div className="footer-btn">
                <Button
                  className="delete-button"
                  type="primary"
                  onClick={() => onProceedBtn()}
                > Proceed
                </Button>
              </div>
            </Modal>
          )
        }
      </Loader>
    </div>
  )
}
