import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Form, Modal, Steps,
} from 'antd';
import { parse } from 'query-string';
import GeneralInformation from '../../container/GeneralInformation';
import Loader from '../../../common/components/Loader';
import OtherKeyPersonnel from '../../container/OtherKeyPersonnel';
import Facilities from '../../container/Facilities';
import PatientAccess from '../../container/PatientAccess';
import StartUpGeneral from '../../container/StartUpGeneral';
import CellAndGeneTherapy from '../../container/CellAndGeneTherapy';
import ItInfrastructure from '../../container/ItInfrastructure';
import Quality from '../../container/Quality';
import {
  addSiteResetAction, createSiteAction, getSiteQuesDataAction, updateSiteAction,
} from './logic';
import { getDeepdiveValuesAction } from '../../container/DeepDiveScreen/logic';
import { getDatasetListDataAction } from '../../container/SearchResults/logic';
import RelationshipContract from '../../container/RelationshipContract';
import PatientSourcing from '../../container/PatientSourcing';
import QualityParent from '../../container/QualityParent';

import { blankGeneralInformation } from './defaultKeys';
import { getStateDataResetAction } from '../../container/GeneralInformation/logic';
import { setAnalytics } from '../../../utils';
import Contracts from '../../container/Contracts';
import CentralisedFunction from '../../container/CentralisedFunction';

export default function AddCentersManually({
  setDefaultValues, flag, setEditModal, setEditCenterModal,
}) {
  const dispatch = useDispatch();
  const updateSiteResponse = useSelector((store) => store.updateSiteResponse);
  const createSiteResponse = useSelector((store) => store.createSiteResponse);
  const defaultValues = useSelector((state) => state.deepDive);
  const { id } = parse(location.search)
  const { query } = parse(location.search)
  const [alertModal, setAlertModal] = useState(false)

  const profile_responses = {}
  if (defaultValues.data.profile_responses) {
    defaultValues.data.profile_responses.forEach((item) => {
      profile_responses[item.question_id] = item.response && item.response[0];
    })
  }

  const defaultCenters = {};
  let defaultOthers = [];

  const configRequired = {
    0: ['centerName', 'address_line1', 'city', 'state', 'country', 'postalcode', 'telephoneNo'],
  }

  if (setDefaultValues) {
    defaultCenters.centerName = { value: defaultValues?.data?.name, flag: false }
    defaultCenters.telephoneNo = { value: defaultValues?.data?.phone_number, flag: false }
    defaultCenters.mobileNo = { value: defaultValues?.data?.mobile_number, flag: false }
    defaultCenters.primary_center = { value: defaultValues?.data?.affiliated_site, flag: false }
    defaultCenters.email = { value: defaultValues?.data?.email, flag: false }
    defaultCenters.address_line1 = { value: defaultValues?.data?.address?.address_line1, flag: false }
    defaultCenters.address_line2 = { value: defaultValues?.data?.address?.address_line2, flag: false }
    defaultCenters.city = { value: defaultValues?.data?.address?.city, flag: false }
    defaultCenters.state = { value: defaultValues?.data?.address?.state, flag: false }
    defaultCenters.country = { value: defaultValues?.data?.address?.country, flag: false }
    defaultCenters.postalcode = { value: defaultValues?.data?.address?.zip, flag: false }
    defaultCenters.parent_flag = { value: defaultValues?.data?.parent_flag, flag: false }
    defaultCenters.last_onsite_visit_date = { value: defaultValues?.data?.last_onsite_visit_date, flag: false }
    defaultCenters.center_type = { value: defaultValues?.data?.center_type, flag: false }

    defaultOthers = defaultValues?.data?.other_personnel.map((itm) => {
      return {
        role: { value: itm.role, flag: false },
        name_of_department: { value: itm.name_of_department, flag: false },
        first_name: { value: itm.first_name, flag: false },
        last_name: { value: itm.last_name, flag: false },
        mobile_number: { value: itm.mobile_number, flag: false },
        fax: { value: itm.fax, flag: false },
        email: { value: itm.email, flag: false },
        same_location: { value: itm.same_location, flag: false },
      }
    })
  }

  const [generalInformation, setGeneralInformation] = useState({ ...defaultCenters, ...profile_responses })
  const [otherData, setOtherData] = useState([...defaultOthers])
  const [facility, setFacility] = useState({ ...profile_responses })
  const [patientAccess, setPatientAccess] = useState({ ...profile_responses })
  const [startUpGeneral, setStartUpGeneral] = useState({ ...profile_responses })
  const [cellAndGene, setcellAndGene] = useState({ ...profile_responses })
  const [contracts, setContracts] = useState({ ...profile_responses })
  const [itInfra, setItInfra] = useState({ ...profile_responses })
  const [quality, setquality] = useState({ ...profile_responses })
  const [relationship, setRelationship] = useState({ ...profile_responses })
  const [patientSource, setPatientSource] = useState({ ...profile_responses })
  const [qualityParent, setqualityParent] = useState({ ...profile_responses })
  const [centralisedFunction, setcentralisedFunction] = useState({ ...profile_responses })
  const [generalInformationForm] = Form.useForm();
  const [generalInformationForm1] = Form.useForm();
  const [otherDataForm] = Form.useForm();
  const [activekey, setActivekey] = useState(1)

  useEffect(() => {
    dispatch(getSiteQuesDataAction())
  }, [])

  useEffect(() => {
    if (updateSiteResponse.flag && flag === 'editCenter') {
      setEditCenterModal(false)
      setAlertModal(false)
      dispatch(getDeepdiveValuesAction({ id, dataset: 'centers' }));
    }

    if (createSiteResponse.flag && flag === 'fromMainCenter') {
      setEditModal(false)
      setAlertModal(false)
      dispatch(
        getDatasetListDataAction({
          query,
          dataset: parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab,
        }),
      );
      dispatch(addSiteResetAction())
    }
    if (updateSiteResponse.error && updateSiteResponse.data.status === 409) {
      setAlertModal(true)
    }
    if (createSiteResponse.error && createSiteResponse.data.status === 409) {
      setAlertModal(true)
    }
  }, [JSON.stringify(updateSiteResponse), JSON.stringify(createSiteResponse)])

  const transformStringToArray = (data) => {
    if (!data) {
      return [];
    }
    let temp = [];
    if (Array.isArray(data)) {
      temp = [...temp, ...data]
    } else {
      temp.push(data)
    }
    return temp
  }

  const processOtherData = (otherPersonnelData) => {
    if (flag === 'fromMainCenter') {
      return otherPersonnelData.map((rec) => {
        if (rec.same_location === '') {
          rec.same_location = false
        }
        return rec
      })
    }
    if (flag === 'editCenter') {
      const blankOther = {
        role: { value: '', flag: false },
        name_of_department: { value: '', flag: false },
        first_name: { value: '', flag: false },
        last_name: { value: '', flag: false },
        mobile_number: { value: [], flag: false },
        fax: { value: [], flag: false },
        email: { value: [], flag: false },
        same_location: { value: false, flag: false },
      }
      const finalOther = otherPersonnelData.map((rec, index) => {
        const temp = {}

        if (rec.same_location.value === '') {
          rec.same_location.value = false
        }

        Object.keys(rec).forEach((k) => {
          if (index < defaultOthers.length) {
            temp[k] = { old: defaultOthers[index][k].value, new: rec[k].value, flag: rec[k].flag }
          } else {
            temp[k] = { old: blankOther[k].value, new: rec[k].value, flag: rec[k].flag }
          }
          return temp
        })
        return temp
      })

      return finalOther
    }
    return null
  }

  const getFinalTimeStamp = (dd) => {
    let finalDate = 0
    if (dd.includes('-')) {
      finalDate = new Date(dd).getTime() / 1000
    } else {
      const d = new Date(dd)
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();

      if (month.length < 2) {
        month = `0${month}`;
      }
      if (day.length < 2) {
        day = `0${day}`;
      }

      finalDate = new Date([year, month, day].join('-')).getTime() / 1000
    }
    return finalDate
  }

  const onDoneClick = () => {
    dispatch(getStateDataResetAction())
    const final = {
      ...generalInformation, other_data: [...otherData], ...patientAccess, ...facility, ...startUpGeneral, ...contracts, ...cellAndGene, ...itInfra, ...quality, ...relationship, ...patientSource, ...qualityParent, ...centralisedFunction,
    }

    const getProfRespData = () => {
      const pr = [];
      const pr_all = defaultValues?.data?.profile_responses;
      const finalResp = {
        ...facility, ...startUpGeneral, ...contracts, ...cellAndGene, ...itInfra, ...quality, ...relationship, ...patientSource, ...qualityParent, ...centralisedFunction,
      }

      Object.keys(finalResp).forEach((k) => {
        const temp = {
          question_id: {
            old: Number(k),
            new: Number(k),
            flag: true,
          },
          text_response: {
            old: [''],
            new: [finalResp[k]],
            flag: true,
          },
        };
        const index = pr_all.findIndex(
          (item) => item.question_id.toString() === k.toString(),
        );
        if (index < 0) {
          pr.push(temp);
        } else {
          pr.push({
            question_id: {
              old: Number(k),
              new: Number(k),
              flag: true,
            },
            text_response: {
              old: pr_all[index].response,
              new: [final[k]],
              flag: true,
            },
          });
        }
        return ''
      });
      return pr
    }
    if (!setDefaultValues) {
      const temp = {
        name: final.centerName,
        address: {
          address_line1: final.address_line1,
          address_line2: final.address_line2,
          city: final.city,
          state: final.state,
          country: final.country,
          zip: final.postalcode,
        },
        email: transformStringToArray(final.email),
        phone_country_code: '',
        phone_number: transformStringToArray(final.telephoneNo),
        mobile_number: transformStringToArray(final.mobileNo),
        other_personnel: processOtherData(final.other_data),
        type: 'SITE',
        last_onsite_visit_date: parseInt(new Date(final.last_onsite_visit_date).getTime() / 1000, 10) || 0,
        parent_flag: final.parent_flag,
        center_type: final.center_type,
        profile_responses: (() => {
          const responses = [];
          const finalKeys = Object.keys(final);
          finalKeys.forEach((key) => {
            if (!isNaN(parseInt(key, 10))) {
              responses.push({
                question_id: parseInt(key, 10),
                int_response: [
                ],
                text_response: [
                  final[key] || '',
                ],
              })
            }
          })
          return responses
        })(),
      }

      dispatch(createSiteAction({
        body: temp,
        params: { duplicate_update: false },
      }))
    } else {
      const updateTemp = {
        name: {
          old: defaultCenters.centerName.value,
          new: final.centerName.value,
          flag: final.centerName.flag,
        },
        address: {
          address_line1: {
            old: defaultCenters.address_line1.value || '',
            new: final.address_line1.value || '',
            flag: final.address_line1.flag,
          },
          address_line2: {
            old: defaultCenters.address_line2.value || '',
            new: final.address_line2.value || '',
            flag: final.address_line2.flag,
          },

          city: {
            old: defaultCenters.city.value || '',
            new: final.city.value || '',
            flag: final.city.flag,
          },
          state: {
            old: defaultCenters.state.value || '',
            new: final.country.value || '',
            flag: final.country.flag,
          },
          country: {
            old: defaultCenters.country.value || '',
            new: final.country.value || '',
            flag: final.country.flag,
          },
          zip: {
            old: defaultCenters.postalcode.value || '',
            new: final.postalcode.value || '',
            flag: final.postalcode.flag,
          },
        },
        email: {
          old: transformStringToArray(defaultCenters.email.value),
          new: transformStringToArray(final.email.value),
          flag: final.email.flag,
        },
        phone_number: {
          old: transformStringToArray(defaultCenters.telephoneNo.value),
          new: transformStringToArray(final.telephoneNo.value),
          flag: final.telephoneNo.flag,
        },
        mobile_number: {
          old: transformStringToArray(defaultCenters.mobileNo.value),
          new: transformStringToArray(final.mobileNo.value),
          flag: final.mobileNo.flag,
        },
        other_personnel: processOtherData(final.other_data),
        center_type: {
          old: defaultCenters.center_type.value,
          new: final.center_type.value,
          flag: final.center_type.flag,
        },
        parent_flag: {
          old: defaultCenters.parent_flag.value,
          new: final.parent_flag.value,
          flag: final.parent_flag.flag,
        },
        last_onsite_visit_date: {
          old: new Date(defaultCenters.last_onsite_visit_date.value).getTime() / 1000 || 0,
          new: getFinalTimeStamp(final.last_onsite_visit_date.value) || 0,
          flag: final.last_onsite_visit_date.flag,
        },
        profile_responses: getProfRespData(),
      }
      dispatch(updateSiteAction({
        params: {
          site_id: defaultValues?.data?.id,
          direct_edit: true,
          duplicate_update: false,
        },
        body: updateTemp,
      }))
    }
    const params = {}
    setAnalytics('centerDeepDiveEdit', 'data', params)
  }

  const onSaveAndAddMoreClick = (setChildOtherData, childOtherData, form, eflag, idx) => {
    otherDataForm.resetFields();
    let defaultOther
    if (flag === 'fromMainCenter') {
      defaultOther = {
        role: '', name_of_department: '', first_name: '', last_name: '', mobile_number: [], fax: [], email: [], same_location: '',
      }
    } else {
      defaultOther = {
        role: { value: '', flag: false },
        name_of_department: { value: '', flag: false },
        first_name: { value: '', flag: false },
        last_name: { value: '', flag: false },
        mobile_number: { value: [], flag: false },
        fax: { value: [], flag: false },
        email: { value: [], flag: false },
        same_location: { value: '', flag: false },
      }
    }
    if (eflag === 'saveEdit') {
      setOtherData((oldState) => {
        const temp = oldState
        temp[idx] = childOtherData
        return temp
      })
    } else {
      setOtherData((oldState) => {
        return [...oldState, childOtherData]
      })
    }
    setChildOtherData(defaultOther)
    form.resetFields();
  }

  const { Step } = Steps;
  const stepsPart1 = [
    {
      title: 'General Information',
      content: <GeneralInformation data={{
        generalInformation, setGeneralInformation, generalInformationForm, generalInformationForm1, flag,
      }}
      />
      ,
    },
    {
      title: 'Other Key Personnel',
      content: <OtherKeyPersonnel data={{
        otherData, setOtherData, flag, onSaveAndAddMoreClick, otherDataForm,
      }}
      />,
    },
    {
      title: 'Facilities',
      content: <Facilities data={{ facility, setFacility, flag }} activekey={activekey} setActivekey={setActivekey} />,
    },
    {
      title: 'Patient Access',
      content: <PatientAccess data={{ patientAccess, setPatientAccess, flag }} />,
    },
    {
      title: 'Start-up-General',
      content: <StartUpGeneral data={{ startUpGeneral, setStartUpGeneral, flag }} />,
    },
    {
      title: 'Contracts',
      content: <Contracts data={{ contracts, setContracts, flag }} />,
    },
    {
      title: 'Cell and Gene Therapy',
      content: <CellAndGeneTherapy data={{ cellAndGene, setcellAndGene, flag }} />,
    },
    {
      title: 'IT Infrastructure',
      content: <ItInfrastructure data={{ itInfra, setItInfra, flag }} />,
    },
    {
      title: 'Quality',
      content: <Quality data={{ quality, setquality, flag }} />,
    },

  ]

  const stepsPart2 = [{
    title: 'Relationship-Contract',
    content: <RelationshipContract data={{ relationship, setRelationship, flag }} />,
  },
  {
    title: 'Patient Sourcing',
    content: <PatientSourcing data={{ patientSource, setPatientSource, flag }} />,
  },
  {
    title: 'Quality Parent',
    content: <QualityParent data={{ qualityParent, setqualityParent, flag }} />,
  },
  {
    title: 'Centralised Function',
    content: <CentralisedFunction data={{ centralisedFunction, setcentralisedFunction, flag }} />,
  }]
  const [steps, setSteps] = useState(stepsPart1)

  useEffect(() => {
    if (generalInformation.parent_flag.value) {
      setSteps([...stepsPart1, ...stepsPart2])
    } else {
      setSteps([...stepsPart1])
    }
  }, [generalInformation.parent_flag.value, activekey, JSON.stringify(facility),
    JSON.stringify(otherData), JSON.stringify(flag), JSON.stringify(patientAccess),
    JSON.stringify(startUpGeneral), JSON.stringify(contracts), JSON.stringify(cellAndGene), JSON.stringify(itInfra),
    JSON.stringify(quality), JSON.stringify(relationship), JSON.stringify(patientSource), JSON.stringify(centralisedFunction),
    JSON.stringify(qualityParent), JSON.stringify(generalInformation)])

  const [isFormValidated, setIsFormValidated] = useState(false);
  const isDisable = () => {
    if (isFormValidated) {
      return true;
    }

    const firstStep = !configRequired[0]?.every((itm) => {
      if (Array.isArray(generalInformation[itm]) || typeof generalInformation[itm] === 'object') {
        return generalInformation[itm]?.value?.length > 0
      }
      return !!generalInformation[itm]
    })
    if (current === 0) {
      return firstStep
    }
    if (current === 7) {
      return firstStep
    }
    return false;
  }

  const [current, setCurrent] = useState(0);

  const next = () => {
    if (current === 2 && activekey < 8) {
      setActivekey((k) => parseInt(k, 10) + 1)
    } else {
      setActivekey(1)
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const onResetClick = () => {
    if (flag === 'editCenter') {
      switch (current) {
        case 0:
          setGeneralInformation({
            centerName: defaultValues?.data?.name,
            telephoneNo: defaultValues?.data?.phone_number,
            mobileNo: defaultValues?.data?.mobile_number,
            email: defaultValues?.data?.email,
            address_line1: defaultValues?.data?.address?.address_line1,
            address_line2: defaultValues?.data?.address?.address_line2,
            city: defaultValues?.data?.address?.city,
            state: defaultValues?.data?.address?.state,
            country: defaultValues?.data?.address?.country,
            postalcode: defaultValues?.data?.address?.zip,
            parent_flag: defaultValues?.data?.parent_flag,
            last_onsite_visit_date: defaultValues?.data?.last_onsite_visit_date,
            center_type: defaultValues?.data?.center_type,
          })

          generalInformationForm.resetFields();
          break;
        case 1:
          setOtherData([])
          otherDataForm.resetFields();
          break;
        case 2:
          setFacility(profile_responses)
          break;
        case 3:
          setPatientAccess(profile_responses)
          break;
        case 4:
          setContracts(profile_responses)
          break;
        case 5:
          setStartUpGeneral(profile_responses)
          break;
        case 6: setcellAndGene(profile_responses);
          break;
        case 7: setItInfra(profile_responses);
          break;
        case 8: setquality(profile_responses);
          break;
        case 9: setRelationship(profile_responses);
          break;
        case 10: setPatientSource(profile_responses);
          break;
        case 11: setqualityParent(profile_responses);
          break;
        default:
      }
    } else {
      switch (current) {
        case 0:
          setGeneralInformation(blankGeneralInformation)
          generalInformationForm.resetFields();
          break;
        case 1:
          setOtherData([])
          otherDataForm.resetFields();
          break;
        case 2:
          setFacility({})
          break;
        case 3:
          setPatientAccess({})
          break;
        case 4:
          setStartUpGeneral({})
          break;
        case 5:
          setContracts({})
          break;
        case 6: setcellAndGene({});
          break;
        case 7: setItInfra({});
          break;
        case 8: setquality({});
          break;
        case 9: setRelationship({});
          break;
        case 10: setPatientSource({});
          break;
        case 11: setqualityParent({});
          break;
        default:
      }
    }
  }
  const onStepsChange = (value) => {
    setCurrent(value);
  };

  const onExistPiYesClick = () => {
    dispatch(getStateDataResetAction())
    const final = {
      ...generalInformation, other_data: [...otherData], ...patientAccess, ...facility, ...startUpGeneral, ...contracts, ...cellAndGene, ...itInfra, ...quality, ...relationship, ...patientSource, ...qualityParent, ...centralisedFunction,
    }

    const getProfRespData = () => {
      const pr = [];
      const pr_all = defaultValues?.data?.profile_responses;
      const finalResp = {
        ...facility, ...startUpGeneral, ...contracts, ...cellAndGene, ...itInfra, ...quality, ...relationship, ...patientSource, ...qualityParent, ...centralisedFunction,
      }

      Object.keys(finalResp).forEach((k) => {
        const temp = {
          question_id: {
            old: Number(k),
            new: Number(k),
            flag: true,
          },
          text_response: {
            old: [''],
            new: [finalResp[k]],
            flag: true,
          },
        };
        const index = pr_all.findIndex(
          (item) => item.question_id.toString() === k.toString(),
        );
        if (index < 0) {
          pr.push(temp);
        } else {
          pr.push({
            question_id: {
              old: Number(k),
              new: Number(k),
              flag: true,
            },
            text_response: {
              old: pr_all[index].response,
              new: [final[k]],
              flag: true,
            },
          });
        }
      });
      return pr
    }
    if (!setDefaultValues) {
      const temp = {
        name: final.centerName,
        address: {
          address_line1: final.address_line1,
          address_line2: final.address_line2,
          city: final.city,
          state: final.state,
          country: final.country,
          zip: final.postalcode,
        },
        email: transformStringToArray(final.email),
        phone_country_code: '',
        phone_number: transformStringToArray(final.telephoneNo),
        mobile_number: transformStringToArray(final.mobileNo),
        other_personnel: processOtherData(final.other_data),
        type: 'SITE',
        last_onsite_visit_date: parseInt(new Date(final.last_onsite_visit_date).getTime() / 1000, 10) || 0,
        parent_flag: final.parent_flag,
        center_type: final.center_type,
        alliance_member_id: final.alliance_member_id.value,
        alliance_type: final.alliance_type,
        oms_loc_id: final.oms_loc_id,
        oms_org_id: final.oms_org_id,
        profile_responses: (() => {
          const responses = [];
          const finalKeys = Object.keys(final);
          finalKeys.forEach((key) => {
            if (!isNaN(parseInt(key, 10))) {
              responses.push({
                question_id: parseInt(key, 10),
                int_response: [
                ],
                text_response: [
                  final[key] || '',
                ],
              })
            }
          })
          return responses
        })(),
      }

      dispatch(createSiteAction({
        body: temp,
        params: {
          duplicate_update: true,
        },
      }))
    } else {
      const updateTemp = {
        name: {
          old: defaultCenters.centerName.value,
          new: final.centerName.value,
          flag: final.centerName.flag,
        },
        address: {
          address_line1: {
            old: defaultCenters.address_line1.value || '',
            new: final.address_line1.value || '',
            flag: final.address_line1.flag,
          },
          address_line2: {
            old: defaultCenters.address_line2.value || '',
            new: final.address_line2.value || '',
            flag: final.address_line2.flag,
          },

          city: {
            old: defaultCenters.city.value || '',
            new: final.city.value || '',
            flag: final.city.flag,
          },
          state: {
            old: defaultCenters.state.value || '',
            new: final.country.value || '',
            flag: final.country.flag,
          },
          country: {
            old: defaultCenters.country.value || '',
            new: final.country.value || '',
            flag: final.country.flag,
          },
          zip: {
            old: defaultCenters.postalcode.value || '',
            new: final.postalcode.value || '',
            flag: final.postalcode.flag,
          },
        },
        email: {
          old: transformStringToArray(defaultCenters.email.value),
          new: transformStringToArray(final.email.value),
          flag: final.email.flag,
        },
        phone_number: {
          old: transformStringToArray(defaultCenters.telephoneNo.value),
          new: transformStringToArray(final.telephoneNo.value),
          flag: final.telephoneNo.flag,
        },
        mobile_number: {
          old: transformStringToArray(defaultCenters.mobileNo.value),
          new: transformStringToArray(final.mobileNo.value),
          flag: final.mobileNo.flag,
        },
        other_personnel: processOtherData(final.other_data),
        center_type: {
          old: defaultCenters.center_type.value,
          new: final.center_type.value,
          flag: final.center_type.flag,
        },
        parent_flag: {
          old: defaultCenters.parent_flag.value,
          new: final.parent_flag.value,
          flag: final.parent_flag.flag,
        },
        last_onsite_visit_date: {
          old: new Date(defaultCenters.last_onsite_visit_date.value).getTime() / 1000 || 0,
          new: getFinalTimeStamp(final.last_onsite_visit_date.value) || 0,
          flag: final.last_onsite_visit_date.flag,
        },
        profile_responses: getProfRespData(),
      }
      dispatch(updateSiteAction({
        params: {
          site_id: defaultValues?.data?.id,
          direct_edit: true,
          duplicate_update: true,
        },
        body: updateTemp,
      }))
    }
    const params = {}
    setAnalytics('centerDeepDiveEdit', 'data', params)
  }

  const onExistPiNoClick = () => {
    if (flag === 'fromEditPI') {
      setEditModal(false)
    } else {
      setEditCenterModal(false)
    }
    setAlertModal(false)
  }

  return (
    <div className="header-wrap">
      <Loader loading={updateSiteResponse.loading || createSiteResponse.loading}>
        <Form.Provider
          onFormChange={(name, { forms }) => {
            const validated = Object.values(forms).every((form) => {
              return form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
            })
            setIsFormValidated(validated)
          }}
        >
          <Steps current={current} onChange={onStepsChange}>
            { steps.map((item) => {
              return <Step key={item.title} title={item.title} />
            })}
          </Steps>

          <div className="steps-content">
            <div className="investigator-page-wrapper">
              {steps[current].content}
            </div>
          </div>
          <div className="steps-action">
            {(flag === 'editCenter' && defaultValues.data.parent_flag === false && current === 7)
          || (flag === 'editCenter' && defaultValues.data.parent_flag === true && current === 10)
          || (flag === 'fromMainCenter' && current === 7)
              ? null : <Button onClick={onDoneClick} disabled={isDisable()}>Save and exit</Button>}
            <Button onClick={onResetClick}>Reset</Button>
            {(current !== 0 && current < steps.length - 1) && (
            <Button onClick={() => next()}>
              Skip
            </Button>
            )}
            {current > 0 && (
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={() => prev()}
              type="primary"
            >
              Previous
            </Button>
            )}
            {current < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => next()}
              disabled={isDisable()}
            >
              Next
            </Button>
            )}
            {current === steps.length - 1 && (
            <Button type="primary" onClick={onDoneClick} disabled={isDisable()}>
              Done
            </Button>
            )}
          </div>
        </Form.Provider>

        {alertModal && (
        <Modal
          title="PI probably exists in LandSCAPE"
          open={alertModal}
          onCancel={() => setAlertModal(false)}
          className="modal-wrapper add-investigator-modal mark-valid-invalid-pi-modal"
          maskClosable={false}
          destroyOnClose
          footer={null}
        >
          Another PI exists in LandSCAPE with same name. Do you still want to continue with your changes?
          <Button onClick={onExistPiYesClick}> Yes </Button>
          <Button onClick={onExistPiNoClick}> No </Button>
        </Modal>
        )}
      </Loader>
    </div>
  )
}
