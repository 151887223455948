import { GENERIC_ACTION } from '../../container/SearchResults/logic'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false,
}

const MERGE_REQUESTS_LIST = 'MERGE_REQUESTS_LIST'
const MERGE_REQUESTS_MORE_LIST = 'MERGE_REQUESTS_MORE_LIST'
const MERGE_REQUESTS_LIST_SUCCESS = 'MERGE_REQUESTS_LIST_SUCCESS'
const MERGE_REQUESTS_MORE_LIST_SUCCESS = 'MERGE_REQUESTS_MORE_LIST_SUCCESS'
const MERGE_REQUESTS_LIST_FAILURE = 'MERGE_REQUESTS_LIST_FAILURE'

export const getMergeRequestsListAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: MERGE_REQUESTS_LIST,
      url: `${process.env.apiUrl}v0/search/listOfRequestsMergedSitePi`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export const getMergeMoreRequestsListAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: MERGE_REQUESTS_MORE_LIST,
      url: `${process.env.apiUrl}v0/search/listOfRequestsMergedSitePi`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export function mergeRequestsListReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case MERGE_REQUESTS_LIST: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case MERGE_REQUESTS_LIST_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case MERGE_REQUESTS_LIST_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    case MERGE_REQUESTS_MORE_LIST_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    default:
      return state
  }
}
