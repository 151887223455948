export const blankGeneralInformation = {
  centerName: '',
  telephoneNo: [],
  mobileNo: [],
  email: [],
  address: '',
  city: '',
  state: '',
  country: '',
  postalcode: '',
  parent_flag: '',
  last_onsite_visit_date: '',
}
export const blankOtherInformation = {
  role: '',
  first_name: '',
  last_name: '',
  mobile_number: '',
  fax: '',
  email: '',
  same_location: '',
}
