import { GENERIC_ACTION } from '../../container/SearchResults/logic'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const CREATE_GROUP = 'CREATE_GROUP';
const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';
const CREATE_GROUP_RESET = 'CREATE_RESET';

export const createGroupResetAction = (payload) => {
  return ({
    type: CREATE_GROUP_RESET,
    payload,
  })
}
export const createGroupAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: CREATE_GROUP,
      url: `${process.env.apiUrl}v0/project/creategroup`,
      method: 'POST',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Group created successfully',
    hideNotification: false, // hide notification on error
  })
}

export function createGroupReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case CREATE_GROUP: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case CREATE_GROUP_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case CREATE_GROUP_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case CREATE_GROUP_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

const ADD_GROUP_MEMBERS = 'ADD_GROUP_MEMBERS';
const ADD_GROUP_MEMBERS_SUCCESS = 'ADD_GROUP_MEMBERS_SUCCESS';
const ADD_GROUP_MEMBERS_FAILURE = 'ADD_GROUP_MEMBERS_FAILURE';
const ADD_GROUP_MEMBERS_RESET = 'ADD_GROUP_MEMBERS_RESET';

export const addGroupMembersResetAction = (payload) => {
  return ({
    type: ADD_GROUP_MEMBERS_RESET,
    payload,
  })
}

export const addGroupMembersAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: ADD_GROUP_MEMBERS,
      url: `${process.env.apiUrl}v0/project/addMembers`,
      method: 'POST',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Group member added successfully',
    hideNotification: false, // hide notification on error
  })
}

export function addGroupMembersReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case ADD_GROUP_MEMBERS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case ADD_GROUP_MEMBERS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case ADD_GROUP_MEMBERS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case ADD_GROUP_MEMBERS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

const GET_GROUPS = 'GET_GROUPS';
const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';

export const getGroupsAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_GROUPS,
      url: `${process.env.apiUrl}v0/project/getgroups`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    message: 'Group created successfully',
    hideNotification: false, // hide notification on error
  })
}

export function getGroupsReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_GROUPS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case GET_GROUPS_SUCCESS: {
      if (action.meta.filter_type) {
        return {
          data: {
            ...state.data, [action.meta.filter_type]: action.payload,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    // case ADD_RISK_RESET: {
    //   return {
    //     data: {},
    //     loading: false,
    //     flag: false,
    //     error: false,
    //   }
    // }
    case GET_GROUPS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

const DELETE_GROUP = 'DELETE_GROUP';
const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';
const DELETE_GROUP_RESET = 'DELETE_GROUP_RESET';

export const deleteGroupResetAction = (payload) => {
  return ({
    type: DELETE_GROUP_RESET,
    payload,
  })
}

export const deleteGroupAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: DELETE_GROUP,
      url: `${process.env.apiUrl}v0/project/deletegroup?innoplexus_id=${payload.innoplexus_id}`,
      method: 'DELETE',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Group deleted successfully',
    hideNotification: false, // hide notification on error
  })
}

export function deleteGroupReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case DELETE_GROUP: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case DELETE_GROUP_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case DELETE_GROUP_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case DELETE_GROUP_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

const GET_GROUP_MEMBERS = 'GET_GROUP_MEMBERS';
const GET_GROUP_MEMBERS_SUCCESS = 'GET_GROUP_MEMBERS_SUCCESS';
const GET_GROUP_MEMBERS_FAILURE = 'GET_GROUP_MEMBERS_FAILURE';
const GET_GROUP_MEMBERS_RESET = 'GET_GROUP_MEMBERS_RESET';

export const getGroupMembersResetAction = (payload) => {
  return ({
    type: GET_GROUP_MEMBERS_RESET,
    payload,
  })
}

export const getGroupMembersAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_GROUP_MEMBERS,
      url: `${process.env.apiUrl}v0/project/getgroupmembers`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    message: 'Group deleted successfully',
    hideNotification: false, // hide notification on error
  })
}

export function getGroupMembersReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_GROUP_MEMBERS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case GET_GROUP_MEMBERS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case GET_GROUP_MEMBERS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_GROUP_MEMBERS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

const DELETE_GROUP_MEMBER = 'DELETE_GROUP_MEMBER';
const DELETE_GROUP_MEMBER_SUCCESS = 'DELETE_GROUP_MEMBER_SUCCESS';
const DELETE_GROUP_MEMBER_FAILURE = 'DELETE_GROUP_MEMBER_FAILURE';
const DELETE_GROUP_MEMBER_RESET = 'DELETE_GROUP_MEMBER_RESET';

export const deleteGroupMemberResetAction = (payload) => {
  return ({
    type: DELETE_GROUP_MEMBER_RESET,
    payload,
  })
}

export const deleteGroupMemberAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: DELETE_GROUP_MEMBER,
      url: `${process.env.apiUrl}v0/project/deleteMember?innoplexus_id=${payload.innoplexus_id}`,
      method: 'DELETE',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Group member deleted successfully',
    hideNotification: false, // hide notification on error
  })
}

export function deleteGroupMemberReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case DELETE_GROUP_MEMBER: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case DELETE_GROUP_MEMBER_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case DELETE_GROUP_MEMBER_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case DELETE_GROUP_MEMBER_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

const UPDATE_GROUP = 'UPDATE_GROUP';
const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';
const UPDATE_GROUP_RESET = 'UPDATE_GROUP_RESET';

export const updateGroupResetAction = (payload) => {
  return ({
    type: UPDATE_GROUP_RESET,
    payload,
  })
}

export const updateGroupAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: UPDATE_GROUP,
      url: `${process.env.apiUrl}v0/project/updategroup?innoplexus_id=${payload.group_innoplexus_id}`,
      method: 'PUT',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Group updated successfully',
    hideNotification: false, // hide notification on error
  })
}

export function updateGroupReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case UPDATE_GROUP: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case UPDATE_GROUP_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case UPDATE_GROUP_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case UPDATE_GROUP_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}
