import Modal from 'antd/lib/modal/Modal';
import { Input, Button, Tag, Tooltip, Radio, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import StarRatings from 'react-star-ratings';
import OutsideClick from '../../../common/components/OutsideClick';
import Loader from '../../../common/components/Loader';
import { getTemplate } from '../../container/SearchBar/template';
import InputWithCopy from '../../container/CopyToClipboard';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';
import {
  getDatasetListDataAction,
  getDatasetListResetAction,
  getMoreDatasetListDataAction,
} from '../../container/SearchResults/logic';
import {
  getAutocompleteValuesAction,
  getAutocompleteValuesResetAction,
  getAutocompleteValuesCancelAction,
} from '../../container/SearchBar/logic';

const { Search } = Input;
const emojiRegex =
  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

const AddCenterNameModal = ({
  modalOpen,
  setModalOpen,
  setSearchVal,
  setShowValue,
  setValue,
  flag,
  Value,
  checkFlag,
  setDisabledFlagForEthnicity,
  setCountry,
  countryField,
  parentFlag,
}) => {
  const [searchText, setSearchText] = useState('');
  const [centerList, setCenterList] = useState([]);
  const [from, setFrom] = useState(1);
  const [query, setQuery] = useState('');
  const [radioClickObj, setRadioClickObj] = useState({});
  // const [checkboxValue, set]
  const [id, setId] = useState('');
  const dispatch = useDispatch();
  const [checkboxVal, setCheckboxVal] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const autoCompleteData = useSelector((state) => state.autoCompleteData);
  const [autosuggestOptions, setAutosuggestOptions] = useState([]);
  const datasetListData = useSelector((state) => state.datasetListData);

  useEffect(() => {
    dispatch(getDatasetListResetAction());
    setCenterList([]);
  }, []);

  const autoSuggestionItemClicked = (itm) => {
    if(flag !== 'otherResearch' && flag !== 'fromCenter') {
    setCountry(itm?.country)
    if(countryField === undefined) {
    if(itm?.country === 'China') {
      setDisabledFlagForEthnicity(true)
    } else {
      setDisabledFlagForEthnicity(false)
    }
  }
}

if(flag !== 'otherResearch' && flag !== 'fromCenter') {
  if(countryField?.value === "") {
    if(itm?.country === 'China') {
      setDisabledFlagForEthnicity(true)
    } else {
      setDisabledFlagForEthnicity(false)
    }
  }
}

    if (flag === 'otherResearch') {
      const obj = {
        key: itm.id,
        value: itm.id,
        label: itm.name,
      };
      if (Value === undefined) {
        setValue([obj]);
      } else if(Value.length) {
        const data = Value.filter((itm) => itm.key === obj.key)
        if(data.length) {
          setValue([...Value])
        } else {
        setValue([...Value, obj]);
        }
      }
      setModalOpen(false);
    } else {
      setSearchVal(itm.id, itm.name);
      setValue(itm.name, itm.id, itm?.alliance_type, itm?.alliance_member_id);
      setShowValue(itm.name);
      setModalOpen(false);
    }
  };

  const renderAutosuggestOptions = () => {
    if (autoCompleteData.flag && autosuggestOptions.length) {
      return autosuggestOptions.map((itm, index) => {
        return (
          <div
            className="search-dropdown-result-list-card"
            role="presentation"
            key={index}
            onClick={() => autoSuggestionItemClicked(itm)}
          >
            {getTemplate(itm.type, itm, index)}
          </div>
        );
      });
    }
    if (autoCompleteData.flag && autosuggestOptions.length === 0) {
      return (
        <div className="autosuggest-no-data-msg">
          <div className="add-manual-pi-wrap">
            <div className="investigator-notfound-text">{parentFlag === false ? 'center not found' : 'parent institution not found'}</div>
            <div className="card-person-img">
              <UserOutlined />
            </div>
            {/* <div className="btn-wrap"><Button className="add-pi-btn" type="primary" onClick={onAddPiManually}>Add Manually</Button></div>
                <div className="footer-text-investigator">Fill in the details and add  the investigator in the system</div> */}
          </div>
        </div>
      );
    }
    return null;
  };

  const tagTitle = {
    tier: 'Tier',
    compliance: 'Compliance',
    alliance: 'Alliance type',
    indications: 'Indications',
    total_number_of_popis: 'Total PIs',
    industry_studies: 'Industry studies',
    interventional_studies: 'Interventional studies',
    observational_studies: 'Observational studies',
    parexel_studies: 'Parexel studies',
    enrollment_subjects: 'Enrolled subjects',
    department: 'Department',
  };
  const getStars = (value) => {
    if (value === 'Class 3') {
      return 1;
    }
    if (value === 'Class 2') {
      return 2;
    }
    if (value === 'Class 1') {
      return 3;
    }
    return 0;
  };

  const handleCenterCheck = (value) => {
    setId(value.id);
    setRadioClickObj(value);
    setCountry(value?.address?.country)
    if(countryField === undefined) {
    if(value?.address?.country === 'China') {
      setDisabledFlagForEthnicity(true)
    } else {
      setDisabledFlagForEthnicity(false)
    }
  }
  if(countryField.value === '') {
    if(value?.address?.country === 'China') {
      setDisabledFlagForEthnicity(true)
    } else {
      setDisabledFlagForEthnicity(false)
    }
  }

  };

  const handleMultipleSelect = (e, value) => {
    setId(1)
    if (e.target.checked) {
      const obj = {
        key: value.id,
        value: value.id,
        label: value.name,
      };
      setCheckboxVal((val) =>  [...val, obj])
    } else {
      const obj = checkboxVal.filter((val) => val.label !== value.name )
      setCheckboxVal(obj)
    }
  }

  const handleOk = () => {
    if (flag === 'otherResearch') {
      if (Value === undefined) {
        checkboxVal.map((itm) => setValue(itm))
      } else if(Value.length) {
        if(checkboxVal.length > Value.length) {
        const arrayValue = checkboxVal.filter((obj) => Value.indexOf(obj) == -1);
        setValue(arrayValue)
        } else if(Value.length > checkboxVal.length) {
          const arrayValue = Value.filter((obj) => checkboxVal.indexOf(obj) == -1);
        setValue(arrayValue)
        } else if(Value.length === checkboxVal.length) {
          setValue(Value)
        }
      }
    } else {
      setSearchVal(radioClickObj.id, radioClickObj.name);
      setShowValue(radioClickObj.name);
      setValue(radioClickObj.name, radioClickObj.id, radioClickObj?.alliance_type, radioClickObj?.alliance_member_id);
    }
    setModalOpen(false);
  };

  useEffect(() => {
    if (datasetListData.flag) {
      setCenterList(datasetListData.data.data);
    }
  }, [JSON.stringify(datasetListData)]);
  const renderCenterCard = () => {
    return centerList?.map((itm) => {
      return (
        <>
          <div className={`card PI`}>
            <div className="card-header">
              <div className="card-title">{itm.name}</div>
            </div>
            <div className="content">
              <div className="leftside">
                <div className="person-details">
                  <div className="contact_name">
                    <div className="title-child">Address:</div>
                    {itm.address ? (
                      <div className="content-child">
                        {itm?.address?.address_line1 && `${itm?.address?.address_line1}, `}
                        {itm?.address?.address_line2 && `${itm?.address?.address_line2}, `}
                        {itm?.address?.city && `${itm?.address?.city}, `}
                        {itm?.address?.state && `${itm?.address?.state}, `}
                        {itm?.address?.country && `${itm?.address?.country}, `}
                        {itm?.address?.zip}
                      </div>
                    ) : (
                      <div className="content-child no-data">No data available</div>
                    )}
                  </div>
                  <div className="emailofcontact">
                    <div className="title-child">Email:</div>
                    {itm.email && itm.email.length ? (
                      <>
                        <div className="content-child">
                          {itm.email.join(', ')}
                          <InputWithCopy text={itm} type="email" />
                        </div>
                      </>
                    ) : (
                      <div className="content-child no-data">No data available</div>
                    )}
                  </div>
                  <div className="mobileOfcontact">
                    <div className="title-child">Phone:</div>
                    {itm.phone_number && itm.phone_number.length ? (
                      <div className="content-child">{itm.phone_number.join(', ')}</div>
                    ) : (
                      <div className="content-child no-data">No data available</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="right-side">
                <div className="tags-data">
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.tier}</div>
                      <div className="sizeOftag" role="presentation">
                        {itm.tier}
                      </div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.alliance}</div>
                      <div className="sizeOftag">{itm.alliance_type ? itm.alliance_type : '-'}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.compliance}</div>
                      <div className="sizeOftag">{itm.compliance ? 'Yes' : 'No'}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.total_number_of_popis}</div>
                      <div className="sizeOftag">{itm.total_number_of_popis}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.industry_studies}</div>
                      <div className="sizeOftag">{itm.industry_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.interventional_studies}</div>
                      <div className="sizeOftag">{itm.interventional_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.observational_studies}</div>
                      <div className="sizeOftag">{itm.observational_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.parexel_studies}</div>
                      <div className="sizeOftag">{itm.parexel_studies}</div>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.enrollment_subjects}</div>
                      <span className="sizeOftag">{itm.enrollment_subjects}</span>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.department}</div>
                      <span className="sizeOftag">{itm.department ? itm.department : '-'}</span>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">{tagTitle.indications}</div>
                      <Tooltip
                        placement="bottom"
                        title={
                          itm.indications && itm.indications.length
                            ? itm.indications.join('; ')
                            : '-'
                        }
                      >
                        <span className="sizeOftag">
                          {itm.indications && itm.indications.length
                            ? itm.indications.join('; ')
                            : '-'}
                        </span>
                      </Tooltip>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">Sources&nbsp;</div>
                      <Tooltip
                        placement="bottom"
                        title={itm.sources && itm.sources.length ? itm.sources.join(', ') : '-'}
                      >
                        <span className="sizeOftag">
                          {itm.sources && itm.sources.length ? itm.sources.join(', ') : '-'}
                        </span>
                      </Tooltip>
                    </div>
                  </Tag>
                  <Tag>
                    <div className="details-abt-tags">
                      <div className="nameOfTag">Center type&nbsp;</div>
                      <Tooltip placement="bottom" title={itm.center_type ? itm.center_type : '-'}>
                        <span className="sizeOftag">{itm.center_type ? itm.center_type : '-'}</span>
                      </Tooltip>
                    </div>
                  </Tag>
                  {itm.pxl_center_sims_ids ? (
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">SIMS IDs&nbsp;</div>
                        <Tooltip
                          placement="bottom"
                          title={
                            itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length
                              ? itm.pxl_center_sims_ids.join(', ')
                              : '-'
                          }
                        >
                          <span className="sizeOftag">
                            {itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length
                              ? itm.pxl_center_sims_ids.join(', ')
                              : '-'}
                          </span>
                        </Tooltip>
                      </div>
                    </Tag>
                  ) : null}
                  {itm.pxl_center_cit_ids ? (
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">Citeline IDs&nbsp;</div>
                        <Tooltip
                          placement="bottom"
                          title={
                            itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length
                              ? itm.pxl_center_cit_ids.join(', ')
                              : '-'
                          }
                        >
                          <span className="sizeOftag">
                            {itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length
                              ? itm.pxl_center_cit_ids.join(', ')
                              : '-'}
                          </span>
                        </Tooltip>
                      </div>
                    </Tag>
                  ) : null}

                  {itm.landscape_id ? (
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">LandSCAPE ID&nbsp;</div>
                        <Tooltip
                          placement="bottom"
                          title={itm.landscape_id ? itm.landscape_id : '-'}
                        >
                          <span className="sizeOftag">
                            {itm.landscape_id ? itm.landscape_id : '-'}
                          </span>
                        </Tooltip>
                      </div>
                    </Tag>
                  ) : null}
                </div>
                <div className="reliability-quality-rating">
                  <div className="reliability rating-sec">
                    <div className="rating-sec-title">Reliability</div>
                    {itm.reliability_class && itm.reliability_class !== '' ? (
                      <StarRatings
                        rating={getStars(itm.reliability_class)}
                        starRatedColor="#8A0050"
                        numberOfStars={3}
                        starDimension="16px"
                        starSpacing="1px"
                        starEmptyColor="#D099B9"
                      />
                    ) : (
                      <div className="content-child no-data">&nbsp;No data available</div>
                    )}
                  </div>
                </div>
              </div>
              {
                flag === 'otherResearch' ? (
                  <Checkbox onChange={(e) => handleMultipleSelect(e,itm)} />

                ) : (
                  <Radio.Group onChange={() => handleCenterCheck(itm)} value={id}>
                  <Radio value={itm.id} />
                </Radio.Group>
                )
              }
            

              {/* <Radio onClick={() => handleInvestigatorCheck(itm)} /> */}
            </div>
          </div>
        </>
      );
    });
  };

  useEffect(() => {
    if (autoCompleteData.flag) {
      setAutosuggestOptions(autoCompleteData.data);
    }
  }, [JSON.stringify(autoCompleteData)]);

  const onChangeInput = (e) => {
    setQuery(e.target.value);
    setFrom(1);
    dispatch(getAutocompleteValuesCancelAction());
    if (e.target.value && e.target.value.trim().length <= 2) {
      setDisplayDropdown(true);
      setSearchText(e.target.value);
      dispatch(getAutocompleteValuesResetAction());
    } else if (
      e.target.value &&
      e.target.value.trim().length > 2 &&
      !emojiRegex.test(e.target.value)
    ) {
      setDisplayDropdown(true);
      setSearchText(e.target.value);
      dispatch(
        getAutocompleteValuesAction({
          query: e.target.value.trim(),
          dataset: 'centers',
          filters: JSON.stringify({ parent_flag: parentFlag ? true : false }),
        })
      );
    } else {
      setDisplayDropdown(false);
      setSearchText('');
    }
  };

  const loadMoreRowsPiList = () => {
    //  setShowDocProfile(true)
    setFrom(from + 1);
    dispatch(
      getMoreDatasetListDataAction({
        query,
        dataset: 'centers',
        from,
        size: 25,
        filters: JSON.stringify({ parent_flag: parentFlag ? true : false }),
      })
    );
  };

  const onSearch = (e) => {
    // setShowDocProfile(false)
    setDisplayDropdown(false);
    setQuery(e);
    dispatch(
      getDatasetListDataAction({
        query: e,
        dataset: 'centers',
        from: 1,
        size: 25,
        filters: JSON.stringify({ parent_flag: parentFlag ? true : false }),
      })
    );
    setFrom(from + 1);
    // setShowDocProfile(true)
  };

  const title = () => {
    if(checkFlag === 'fromEditPI' && parentFlag === false) {
      return 'Search Center'
    } else if(checkFlag === 'editCenter' && parentFlag === true) {
      return 'Search Parent'
    } else if(parentFlag === true) {
      return 'Add Parent'
    }
    else return 'Add Center'
  }

  return (
    <Modal
      className="center-name-suggestion-modal"
      title={title()}
      // title="Add Center"
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      onOk={handleOk}
      cancelButtonProps={centerList.length === 0 && { style: { display: 'none' }}}
      okButtonProps={centerList.length === 0 ? { style: { display: 'none' }} : (flag === 'otherResearch' && checkboxVal.length === 0) ? {disabled: true} : id === '' ? {disabled: true} : '' }

      // okButtonProps={{ disabled: centerList.length === 0 }}
    >
      <div className="add-investigator-modal-search">
        <div
          className="add-investigator-modal-search-title"
          style={{
            color: '#000',
            fontSize: '16px',
            letterSpacing: '0',
            lineHeight: '19px',
            textAlign: 'center',
            paddingBottom: '20px',
          }}
        >
          Search the {parentFlag === false ? 'center' : 'parent institution'} to be added{' '}
        </div>
        <div className="search-bar search-wrap center-list">
          <div className="search-bar-wrap">
            <div className="search-box">
              <Search
                placeholder={parentFlag === false ? 'search center name' : 'search Parent institution name'}
                //   placeholder={(flagName === 'runAnalysis' || flagName === 'savedAnalysis') ? 'Search by investigator name' : fieldToSearch === 'all' ? 'Search for Investigator, Center, Project, Network and Clinical Trial' : `Search for ${(fieldPlaceholderMapping[fieldToSearch])}`}
                allowClear
                value={searchText}
                loading={autoCompleteData.loading}
                enterButton={<Button disabled={!(searchText.length > 2)}>{parentFlag === false ? 'Search Center' : 'Search Parent Institution'}</Button>}
                size="large"
                maxLength="256"
                onSearch={onSearch}
                onChange={onChangeInput}
              />
            </div>
          </div>
          <div className="search-dropdown-result-content">
            {displayDropdown ? (
              <OutsideClick
                ignoreClickWithinClass="search-bar"
                onClickOutside={() => {
                  setDisplayDropdown(false);
                }}
              >
                <div className="search-dropdown-result">
                  <div className="search-dropdown-result-list">
                    <Loader loading={autoCompleteData.loading} error={autoCompleteData.error}>
                      {renderAutosuggestOptions()}
                    </Loader>
                  </div>
                </div>
              </OutsideClick>
            ) : null}
          </div>
          <Loader
              loading={datasetListData.loading}
              error={datasetListData.error}
              style={{ width: '100%' }}
            >
          {centerList.length !==0 && (
              <div className="inner-row-search-data">
                {/* {showDocProfile && ( */}
                <LazyLoadDiv
                  className="card-list scrollbar"
                  id="pi-list-in-search"
                  total={datasetListData.data.total}
                  currentTotal={(datasetListData.data.data || []).length}
                  loadMoreRows={() => loadMoreRowsPiList()}
                  height="calc(100vh - 400px)"
                >
                  {renderCenterCard()}
                </LazyLoadDiv>
                {/* )} */}
              </div>
          )}
           </Loader>
        </div>
      </div>
    </Modal>
  );
};

export default AddCenterNameModal;
