import { GENERIC_ACTION } from '../../container/SearchResults/logic'

const GET_PROJECT_FILTER = 'GET_PROJECT_FILTER';
const GET_PROJECT_FILTER_SUCCESS = 'GET_PROJECT_FILTER_SUCCESS';
const GET_PROJECT_FILTER_RESET = 'GET_PROJECT_FILTER_RESET';
const GET_PROJECT_FILTER_FAILURE = 'GET_PROJECT_FILTER_FAILURE';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getProjectFilterAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_PROJECT_FILTER,
    url: `${process.env.apiUrl}v0/project/runprojectfilters`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getProjectFilterResetAction = () => ({
  type: GET_PROJECT_FILTER_RESET,
})

export function getProjectFilterReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PROJECT_FILTER: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PROJECT_FILTER_SUCCESS: {
      if (action.meta.filter_type) {
        return {
          data: {
            ...state.data, [action.meta.filter_type]: action.payload,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PROJECT_FILTER_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_PROJECT_FILTER_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const GET_PROJECT_FILTER_FOR_COUNTRY = 'GET_PROJECT_FILTER_FOR_COUNTRY';
const GET_PROJECT_FILTER_FOR_COUNTRY_SUCCESS = 'GET_PROJECT_FILTER_FOR_COUNTRY_SUCCESS';
const GET_PROJECT_FILTER_FOR_COUNTRY_RESET = 'GET_PROJECT_FILTER_FOR_COUNTRY_RESET';
const GET_PROJECT_FILTER_FOR_COUNTRY_FAILURE = 'GET_PROJECT_FILTER_FOR_COUNTRY_FAILURE';

export const getProjectFilterForCountryAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_PROJECT_FILTER_FOR_COUNTRY,
    url: `${process.env.apiUrl}v0/projects/getcountries`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getProjectFilterForCountryResetAction = () => ({
  type: GET_PROJECT_FILTER_FOR_COUNTRY_RESET,
})

export function getProjectFilterForCountryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PROJECT_FILTER_FOR_COUNTRY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PROJECT_FILTER_FOR_COUNTRY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PROJECT_FILTER_FOR_COUNTRY_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_PROJECT_FILTER_FOR_COUNTRY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
