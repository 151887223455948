import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { getSearchResultFilterAction } from '../../container/SearchResults/logic';

const { Option } = Select;

const RenderFilterCenterType = ({ data, setData, fieldToSearch }) => {
  const [centerTypeData, setCenterTypeData] = useState([]);
  const searchResultFilterData = useSelector((state) => state.searchResultFilterData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getSearchResultFilterAction(
        {
          dataset: fieldToSearch === 'parent_centers' ? 'centers' : fieldToSearch,
          type: 'center_type',
        },
        {
          filter_type: 'center_type',
          key: 'center_type',
        },
      ),
    );
  }, []);

  useEffect(() => {
    if (searchResultFilterData.flag) {
      setCenterTypeData(searchResultFilterData?.data?.center_type);
    }
  }, [JSON.stringify(searchResultFilterData)]);

  const handleChange = (e) => {
    setData((old) => {
      const temp = { ...old };
      let value = '';
      value = e;
      temp.center_type = value;
      return temp;
    });
  };

  const getValue = () => {
    return data.center_type;
  };

  return (
    <div className="race-inner-wrap full-width-input">
      <div className="advanced-search-content-input-sec sources-inner-wrap">
        <div className="searchresults-filter-section-filter">
          <div className="searchresults-filter-section-filter-select">
            <div className="group-input-fields">
              <div className="value-wrap">
                <Select
                  mode="multiple"
                  showSearch
                  allowClear
                  placeholder="Select Center Type"
                  onChange={(e) => handleChange(e)}
                  value={getValue()}
                >
                  {centerTypeData
                    && centerTypeData?.map((itm, index) => {
                      return (
                        <Option key={index} value={itm.key}>
                          {itm.category}
                        </Option>
                      );
                    })}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderFilterCenterType;
