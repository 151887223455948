// import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../../container/SearchResults/logic'

const FETCH_COUNTRY = 'FETCH_COUNTRY'
const FETCH_COUNTRY_SUCCESS = 'FETCH_COUNTRY_SUCCESS'
const FETCH_COUNTRY_RESET = 'FETCH_COUNTRY_RESET'
const FETCH_COUNTRY_FAILURE = 'FETCH_COUNTRY_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const fetchCountryAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: FETCH_COUNTRY,
      url: `${process.env.apiUrl}v0/search/countryProfileFilters`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const fetchCountryResetAction = () => ({
  type: FETCH_COUNTRY_RESET,
})

export function fetchCountryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_COUNTRY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case FETCH_COUNTRY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case FETCH_COUNTRY_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case FETCH_COUNTRY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
