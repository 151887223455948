import { GENERIC_ACTION } from '../../container/SearchResults/logic'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

const GET_COUNTRY_SUMMARY = 'GET_COUNTRY_SUMMARY'
const GET_COUNTRY_SUMMARY_SUCCESS = 'GET_COUNTRY_SUMMARY_SUCCESS'
const GET_COUNTRY_SUMMARY_RESET = 'GET_COUNTRY_SUMMARY_RESET'
const GET_COUNTRY_SUMMARY_FAILURE = 'GET_COUNTRY_SUMMARY_FAILURE'

export const getCountrySummaryAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_COUNTRY_SUMMARY,
      url: `${process.env.apiUrl}/v0/projects/getCountrySummaryResult`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const getCountrySummaryResetAction = () => ({
  type: GET_COUNTRY_SUMMARY_RESET,
})

export function getCountrySummaryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COUNTRY_SUMMARY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_COUNTRY_SUMMARY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_COUNTRY_SUMMARY_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_COUNTRY_SUMMARY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const UPDATE_COUNTRY_SUMMARY = 'UPDATE_COUNTRY_SUMMARY'
const UPDATE_COUNTRY_SUMMARY_SUCCESS = 'UPDATE_COUNTRY_SUMMARY_SUCCESS'
const UPDATE_COUNTRY_SUMMARY_RESET = 'UPDATE_COUNTRY_SUMMARY_RESET'
const UPDATE_COUNTRY_SUMMARY_FAILURE = 'UPDATE_COUNTRY_SUMMARY_FAILURE'

export const updateCountrySummaryAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: UPDATE_COUNTRY_SUMMARY,
      url: `${process.env.apiUrl}/v0/projects/updateCountrySummary?project_id=${payload.project_id}&innoplexus_id=${payload.innoplexus_id}`,
      method: 'PUT',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const updateCountrySummaryResetAction = () => ({
  type: UPDATE_COUNTRY_SUMMARY_RESET,
})

export function updateCountrySummaryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_COUNTRY_SUMMARY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case UPDATE_COUNTRY_SUMMARY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case UPDATE_COUNTRY_SUMMARY_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case UPDATE_COUNTRY_SUMMARY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
