import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryDataAction } from '../../container/GeneralInformation/logic';

export default function RenderFilterCountry(props) {
  const {
    data, setData,
  } = props;

  const { Option } = Select;
  const dispatch = useDispatch();

  const getCountryData = useSelector((store) => store.getCountryData);
  const [countryList, setCountryList] = useState([])

  useEffect(() => {
    dispatch(getCountryDataAction())
  }, [])

  useEffect(() => {
    if (getCountryData.flag) {
      setCountryList(getCountryData?.data?.distinct_countries)
    }
  }, [JSON.stringify(getCountryData)])

  const handleChange = (e) => {
    setData((old) => {
      const temp = { ...old };
      let value = '';
      value = e;
      temp.country = value;
      return temp;
    });
  };

  const getValue = () => {
    return data.country;
  };

  return (
    <div className="race-inner-wrap full-width-input">
      <div className="advanced-search-content-input-sec sources-inner-wrap">
        <div className="searchresults-filter-section-filter">
          <div className="searchresults-filter-section-filter-select">
            <div className="group-input-fields">
              <div className="value-wrap">
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Search Country"
                  onChange={(e) => handleChange(e)}
                  maxTagCount={7}
                  value={getValue()}
                >
                  {countryList && countryList?.map((itm) => {
                    return <Option value={itm}>{itm}</Option>
                  })}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
