import { stringify } from 'query-string';
import {
  map, catchError, mergeMap, takeUntil,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { apiCallwithoutCT } from '../../../utils';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const UPLOAD_BULK_UPDATE_FILE = 'UPLOAD_BULK_UPDATE_FILE';
const UPLOAD_BULK_UPDATE_FILE_CANCEL = 'UPLOAD_BULK_UPDATE_FILE_CANCEL';
const UPLOAD_BULK_UPDATE_FILE_RESET = 'UPLOAD_BULK_UPDATE_FILE_RESET';
const UPLOAD_BULK_UPDATE_FILE_SUCCESS = 'UPLOAD_BULK_UPDATE_FILE_SUCCESS';
const UPLOAD_BULK_UPDATE_FILE_FAILURE = 'UPLOAD_BULK_UPDATE_FILE_FAILURE';

export const uploadBulkUpdateFileCancelAction = () => ({
  type: UPLOAD_BULK_UPDATE_FILE_CANCEL,
});

export const uploadBulkUpdateFileAction = (payload) => ({
  type: UPLOAD_BULK_UPDATE_FILE,
  payload,
});

const uploadBulkUpdateFileSuccess = (payload) => ({
  type: UPLOAD_BULK_UPDATE_FILE_SUCCESS,
  payload,
  showNotification: false,
  message: 'File uploaded successfully',
});

export const uploadBulkUpdateFileResetAction = () => ({
  type: UPLOAD_BULK_UPDATE_FILE_RESET,
});

export const uploadBulkUpdateFileEpic = (actions$) => actions$.pipe(
  ofType(UPLOAD_BULK_UPDATE_FILE),
  mergeMap((action) => ajax(
    apiCallwithoutCT(
      `${process.env.apiUrl}v0/search/bulkUpdate?${stringify(action.payload.params)}`,
      'POST',
      action.payload.body,
    ),
  ).pipe(
    map((res) => uploadBulkUpdateFileSuccess(res)),
    takeUntil(actions$.pipe(ofType(UPLOAD_BULK_UPDATE_FILE_CANCEL))),
    catchError((err) => of({
      type: UPLOAD_BULK_UPDATE_FILE_FAILURE,
      payload: err,
    })),
  )),
);

export function uploadBulkUpdateFileReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case UPLOAD_BULK_UPDATE_FILE: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case UPLOAD_BULK_UPDATE_FILE_SUCCESS: {
      return {
        data: action.payload.response,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case UPLOAD_BULK_UPDATE_FILE_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      };
    }
    case UPLOAD_BULK_UPDATE_FILE_FAILURE: {
      return {
        data: action.payload.response,
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}
