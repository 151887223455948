import { Button, Modal } from 'antd'
import { stringify } from 'query-string';
import React from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RenderFilterAgeAndSex from '../../container/RenderFilterAgeAndSex';
import RenderFilterAllianceType from '../../container/RenderFilterAllianceType';
import RenderFilterEmpByInsurance from '../../container/RenderFilterEmpByInsurance';
import RenderFilterIncomeByInsurance from '../../container/RenderFilterIncomeByInsurance';
import RenderFilterIndications from '../../container/RenderFilterIndications';
import RenderFilterNoOfPIS from '../../container/RenderFilterNoOfPIS';
import RenderProfile from '../../container/RenderFilterProfile';
import RenderFilterRace from '../../container/RenderFilterRace';
import RenderFilterSources from '../../container/RenderFilterSources';
import RenderFilterStudies from '../../container/RenderFilterStudies';
import RenderFilterTier from '../../container/RenderFilterTier';
import { getDatasetListDataAction } from '../../container/SearchResults/logic';
import RenderFilterCountry from '../RenderFilterCountry';
import RenderFilterTherapeuticArea from '../RenderFilterTherapeuticArea';
import RenderFilterCenterType from '../RenderFilterCenterType';
import RenderFilterKOLScoreClass from '../RenderFilterKOLScoreClass';
import RenderFilterEmergingKOLScore from '../RenderFilterEmergingKOLScore';
import RenderFilterKOLScore from '../../container/RenderFilterKOLScore';
import RenderFilterLandscapeIDSearch from '../RenderFilterLandscapeIDSearch';

export default function RenderAdvanceSearchModal({
  setData, filterData, showAdvanceSearchModal, setAdvanceSearchModal, fieldToSearch,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleAdd = (idx, type) => {
    const blankData = {
      profile_fields: [{
        field: '', compare: '', value: '', operator: 'AND',
      }],
      race_ethnicity: [{
        field: '', type: '', compare: '', value: '', operator: 'AND',
      }],
      age_sex: [{
        field: '', compare: '', value: '', operator: 'AND',
      }],
      employment_by_insurance: [{
        field: '', type: '', label: '', compare: '', value: '', operator: 'AND',
      }],
      income_by_insurance: [{
        field: '', compare: '', value: '', operator: 'AND',
      }],
      studies: [{
        field: '', compare: '', value: '', operator: 'AND',
      }],
      cls_kol_score: [{
        field: '', compare: '', value: '', operator: 'AND',
      }],
      sources: [],
      indications: [],
      alliance_type: [],
      landscape_id: [],
      total_number_of_pis: [{
        field: '', compare: '', value: '', operator: 'AND',
      }],
      tier: [],
      country: [],
      center_type: [],
      therapeutic_area: [],
      census_available_countries: ['United States'],
      emerging_tag: [],
      cls_kol_score_class: [],
    }
    setData((old) => {
      const temp = { ...old };
      temp[type] = [
        ...old[type].slice(0, idx + 1),
        blankData[type][0],
        ...old[type].slice(idx + 1),
      ];
      return temp;
    });
  };

  const onCancelModal = () => {
    setAdvanceSearchModal(false);
  }

  const updateFilterData = (data) => {
    function insertInTemp(key, d, temp) {
      if (key in temp) {
        temp[key].push(d);
      } else {
        temp[key] = [d];
      }
    }
    const nonFieldskeys = new Set(['sources', 'alliance_type', 'indications', 'tier', 'country', 'census_available_countries', 'therapeutic_area', 'center_type', 'landscape_id']);

    const nonFieldskeys1 = new Set(['sources', 'alliance_type', 'indications', 'tier', 'country', 'census_available_countries', 'therapeutic_area', 'emerging_tag', 'cls_kol_score_class', 'landscape_id']);
    const temp = {};

    if (fieldToSearch === 'investigators') {
      Object.keys(data).forEach((key) => {
        const eachData = data[key];

        if (eachData) {
          eachData.forEach((d) => {
            if (nonFieldskeys1.has(key)) {
              if (d.length > 0) {
                insertInTemp(key, d, temp);
              }
            } else if ((d.field || d.value)) {
              insertInTemp(key, d, temp);
            }
          });
        }
      });
      return temp
    }

    Object.keys(data).forEach((key) => {
      const eachData = data[key];

      if (eachData) {
        eachData.forEach((d) => {
          if (nonFieldskeys.has(key)) {
            if (d.length > 0) {
              insertInTemp(key, d, temp);
            }
          } else if ((d.field || d.value)) {
            insertInTemp(key, d, temp);
          }
        });
      }
    });
    return temp
  };

  const onApplyFilterClick = () => {
    const advanceSearchData = updateFilterData(filterData)
    localStorage.setItem('all_advance_search', JSON.stringify(filterData));
    localStorage.setItem('advance_search', JSON.stringify({ advanced_filters: advanceSearchData }))
    const paramss = {
      searchfield: fieldToSearch,
      currentTab: fieldToSearch === 'all' ? 'investigators' : fieldToSearch,
    };
    if (advanceSearchData) {
      dispatch(
        getDatasetListDataAction({
          dataset: fieldToSearch === 'parent_centers' ? 'centers' : fieldToSearch,
          from: 1,
          size: 50,
          filters: JSON.stringify({ advanced_filters: advanceSearchData }),
        }),
      );
    }
    history.push(`/searchresults?${stringify(paramss)}`);
    setAdvanceSearchModal(false);
  }

  const onClearFilterClick = () => {
    setData({
      profile_fields: [{
        field: '', compare: '', value: '', operator: '',
      }],
      race_ethnicity: [{
        field: '', type: '', compare: '', value: '', operator: '',
      }],
      age_sex: [{
        field: '', compare: '', value: '', operator: '',
      }],
      employment_by_insurance: [{
        field: '', type: '', label: '', compare: '', value: '', operator: '',
      }],
      income_by_insurance: [{
        field: '', compare: '', value: '', operator: '',
      }],
      studies: [{
        field: '', compare: '', value: '', operator: '',
      }],
      cls_kol_score: [{
        field: '', compare: '', value: '', operator: '',
      }],
      sources: [],
      indications: [],
      alliance_type: [],
      total_number_of_pis: [{
        field: '', compare: '', value: '', operator: '',
      }],
      tier: [],
      country: [],
      center_type: [],
      therapeutic_area: [],
      census_available_countries: ['United States'],
      emerging_tag: [],
      cls_kol_score_class: [],
      landscape_id: [],
    })
  }

  const handleMinus = (idx, type) => {
    setData((old) => {
      const temp = { ...old };
      temp[type].splice(idx, 1);
      return temp;
    });
  };
  const renderFIelds = () => {
    return (
      <div className="advanced-search-content">
        <div className="advanced-search-content-body">
          <div className="advanced-search-content-input-sec profile-inner-wrap">
            <div className="name-wrap">Profile Fields</div>
            {filterData.profile_fields.map((d, idx) => {
              return (
                <RenderProfile
                  data={filterData}
                  idx={idx}
                  setData={setData}
                  handleAdd={handleAdd}
                  handleMinus={handleMinus}
                  key={idx}
                />
              );
            })}

            <div className="name-wrap">Population - Race & Ethnicity</div>
            {filterData.race_ethnicity.map((d, idx) => {
              return (
                <>
                  <RenderFilterRace
                    data={filterData}
                    idx={idx}
                    setData={setData}
                    handleAdd={handleAdd}
                    handleMinus={handleMinus}
                    key={idx}
                  />
                </>
              );
            })}

            <div className="name-wrap">Population - Age & Sex</div>
            {filterData.age_sex.map((d, idx) => {
              return (
                <>
                  <RenderFilterAgeAndSex
                    data={filterData}
                    idx={idx}
                    setData={setData}
                    handleAdd={handleAdd}
                    handleMinus={handleMinus}
                    key={idx}
                  />
                </>
              );
            })}
          </div>

          <div className="name-wrap">Population - Employment by insurance</div>
          {filterData.employment_by_insurance.map((d, idx) => {
            return (
              <>
                <RenderFilterEmpByInsurance
                  data={filterData}
                  idx={idx}
                  setData={setData}
                  handleAdd={handleAdd}
                  handleMinus={handleMinus}
                  key={idx}
                />
              </>
            );
          })}

          <div className="name-wrap">Population - Income by Insurance</div>
          {filterData.income_by_insurance.map((d, idx) => {
            return (
              <>
                <RenderFilterIncomeByInsurance
                  data={filterData}
                  idx={idx}
                  setData={setData}
                  handleAdd={handleAdd}
                  handleMinus={handleMinus}
                  key={idx}
                />
              </>
            );
          })}

          <div className="name-wrap"># Studies</div>
          {filterData.studies.map((d, idx) => {
            return (
              <>
                <RenderFilterStudies
                  data={filterData}
                  idx={idx}
                  setData={setData}
                  handleAdd={handleAdd}
                  handleMinus={handleMinus}
                  key={idx}
                />
              </>
            );
          })}

          {(fieldToSearch === 'investigators') && (
            <>
              <div className="name-wrap">KOL Score</div>
              {filterData?.cls_kol_score?.map((d, idx) => {
                return (
                  <>
                    <RenderFilterKOLScore
                      data={filterData}
                      idx={idx}
                      setData={setData}
                      handleAdd={handleAdd}
                      handleMinus={handleMinus}
                      key={idx}
                    />
                  </>
                );
              })}
            </>
          )}

          {(fieldToSearch === 'centers' || fieldToSearch === 'parent_centers')
          && (

            <div>
              <div className="name-wrap"># PIs</div>
              {filterData.total_number_of_pis.map((d, idx) => {
                return (
                  <>
                    <RenderFilterNoOfPIS
                      data={filterData}
                      idx={idx}
                      setData={setData}
                      handleAdd={handleAdd}
                      handleMinus={handleMinus}
                      key={idx}
                    />
                  </>
                );
              })}
            </div>
          )}
          <div className="combine-div">
            <div className="advanced-filter-input">
              <div className="filter-value-title name-wrap">Sources</div>

              <RenderFilterSources
                data={filterData}
                setData={setData}
                fieldToSearch={fieldToSearch}
              />
            </div>
            <div className="advanced-filter-input">

              <div className="filter-value-title name-wrap">Indications</div>
              <RenderFilterIndications
                data={filterData}
                setData={setData}
                fieldToSearch={fieldToSearch}
              />
            </div>
          </div>

          <div className="combine-div">
            <div className="advanced-filter-input">

              <div className="filter-value-title name-wrap">Alliance Type</div>
              <RenderFilterAllianceType
                data={filterData}
                setData={setData}
                fieldToSearch={fieldToSearch}
              />
            </div>
            {
              fieldToSearch !== 'parent_centers' && (
                <div className="advanced-filter-input">
                  <div className="name-wrap">Tier</div>
                  <RenderFilterTier
                    data={filterData}
                    setData={setData}
                  />
                </div>
              )
            }
          </div>

          <div className="combine-div">
            <div className="advanced-filter-input">

              <div className="name-wrap">Country</div>
              <RenderFilterCountry
                data={filterData}
                setData={setData}
                fieldToSearch={fieldToSearch}
              />
            </div>
            <div className="advanced-filter-input">
              <div className="name-wrap">Therapeutic Area</div>
              <RenderFilterTherapeuticArea
                data={filterData}
                setData={setData}
                fieldToSearch={fieldToSearch}
              />
            </div>
          </div>
          {
            (fieldToSearch === 'investigators') && (
              <div className="combine-div">
                <div className="advanced-filter-input">

                  <div className="name-wrap">KOL Score Class</div>
                  <RenderFilterKOLScoreClass
                    data={filterData}
                    setData={setData}
                    fieldToSearch={fieldToSearch}
                  />
                </div>
                <div className="advanced-filter-input">
                  <div className="name-wrap">Emerging KOL Class </div>
                  <RenderFilterEmergingKOLScore
                    data={filterData}
                    setData={setData}
                    fieldToSearch={fieldToSearch}
                  />
                </div>
              </div>

            )
          }
          {
          (fieldToSearch === 'centers' || fieldToSearch === 'parent_centers') && (
            <div className="combine-div">
              <div className="advanced-filter-input">
                <div className="name-wrap">Center Type</div>
                <RenderFilterCenterType
                  data={filterData}
                  setData={setData}
                  fieldToSearch={fieldToSearch}
                />
              </div>
              <div className="advanced-filter-input">

                <div className="name-wrap">Landscape ID</div>
                <RenderFilterLandscapeIDSearch
                  data={filterData}
                  setData={setData}
                  fieldToSearch={fieldToSearch}
                />
              </div>
            </div>

          )
        }
          {
            (fieldToSearch === 'investigators') && (
            <div className="combine-div">
              <div className="advanced-filter-input">

                <div className="name-wrap">Landscape ID</div>
                <RenderFilterLandscapeIDSearch
                  data={filterData}
                  setData={setData}
                  fieldToSearch={fieldToSearch}
                />
              </div>
            </div>
            )
}
        </div>
        <div className="advanced-search-footer">
          <div className="advance-search-btn">
            <Button className="clear-btn" type="default" onClick={onClearFilterClick}> Clear </Button>
            <Button type="primary" onClick={onApplyFilterClick}> Apply </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Modal
      style={{ width: '100%' }}
      open={showAdvanceSearchModal}
      className="advanced-search-modal"
      onCancel={onCancelModal}
      destroyOnClose
      footer={null}
      title="Advanced Search"
    >
      {renderFIelds()}
    </Modal>
  )
}
