import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../../container/SearchResults/logic'

const GET_PROJECT_ANALYSIS = 'GET_PROJECT_ANALYSIS'
const GET_PROJECT_ANALYSIS_MORE = 'GET_PROJECT_ANALYSIS_MORE'
const GET_PROJECT_ANALYSIS_MORE_SUCCESS = 'GET_PROJECT_ANALYSIS_MORE_SUCCESS'
const GET_PROJECT_ANALYSIS_SUCCESS = 'GET_PROJECT_ANALYSIS_SUCCESS'
const GET_PROJECT_ANALYSIS_RESET = 'GET_PROJECT_ANALYSIS_RESET'
const GET_PROJECT_ANALYSIS_FAILURE = 'GET_PROJECT_ANALYSIS_FAILURE'

const CREATE_PROJECT_ANALYSIS = 'CREATE_PROJECT_ANALYSIS'
const CREATE_PROJECT_ANALYSIS_SUCCESS = 'CREATE_PROJECT_ANALYSIS_SUCCESS'
const CREATE_PROJECT_ANALYSIS_FAILURE = 'CREATE_PROJECT_ANALYSIS_FAILURE'

const CREATE_PROJECT_QUERY = 'CREATE_PROJECT_QUERY'
const CREATE_PROJECT_QUERY_SUCCESS = 'CREATE_PROJECT_QUERY_SUCCESS'
const CREATE_PROJECT_QUERY_FAILURE = 'CREATE_PROJECT_QUERY_FAILURE'
const CREATE_PROJECT_QUERY_RESET = 'CREATE_PROJECT_QUERY_RESET'

const GET_QUERIES = 'GET_QUERIES'
const GET_QUERIES_SUCCESS = 'GET_QUERIES_SUCCESS'
const GET_QUERIES_RESET = 'GET_QUERIES_RESET'
const GET_QUERIES_FAILURE = 'GET_QUERIES_FAILURE'

const UPDATE_ANALYSIS = 'UPDATE_ANALYSIS'
const UPDATE_ANALYSIS_SUCCESS = 'UPDATE_ANALYSIS_SUCCESS'
const UPDATE_ANALYSIS_RESET = 'UPDATE_ANALYSIS_RESET'
const UPDATE_ANALYSIS_FAILURE = 'UPDATE_ANALYSIS_FAILURE'

const UPDATE_QUERY = 'UPDATE_QUERY'
const UPDATE_QUERY_SUCCESS = 'UPDATE_QUERY_SUCCESS'
const UPDATE_QUERY_RESET = 'UPDATE_QUERY_RESET'
const UPDATE_QUERY_FAILURE = 'UPDATE_QUERY_FAILURE'

const DELETE_ANALYSIS = 'DELETE_ANALYSIS'
const DELETE_ANALYSIS_SUCCESS = 'DELETE_ANALYSIS_SUCCESS'
const DELETE_ANALYSIS_RESET = 'DELETE_ANALYSIS_RESET'
const DELETE_ANALYSIS_FAILURE = 'DELETE_ANALYSIS_FAILURE'

const DELETE_QUERIES = 'DELETE_QUERIES'
const DELETE_QUERIES_SUCCESS = 'DELETE_QUERIES_SUCCESS'
const DELETE_QUERIES_RESET = 'DELETE_QUERIES_RESET'
const DELETE_QUERIES_FAILURE = 'DELETE_QUERIES_FAILURE'

const MARK_INVALID_PROJECT = 'MARK_INVALID_PROJECT'
const MARK_INVALID_PROJECT_SUCCESS = 'MARK_INVALID_PROJECT_SUCCESS'
const MARK_INVALID_PROJECT_RESET = 'MARK_INVALID_PROJECT_RESET'
const MARK_INVALID_PROJECT_FAILURE = 'MARK_INVALID_PROJECT_FAILURE'

const MARK_INVALID_ANALYSIS = 'MARK_INVALID_ANALYSIS'
const MARK_INVALID_ANALYSIS_SUCCESS = 'MARK_INVALID_ANALYSIS_SUCCESS'
const MARK_INVALID_ANALYSIS_RESET = 'MARK_INVALID_ANALYSIS_RESET'
const MARK_INVALID_ANALYSIS_FAILURE = 'MARK_INVALID_ANALYSIS_FAILURE'

const MARK_INVALID_QUERY = 'MARK_INVALID_QUERY'
const MARK_INVALID_QUERY_SUCCESS = 'MARK_INVALID_QUERY_SUCCESS'
const MARK_INVALID_QUERY_RESET = 'MARK_INVALID_QUERY_RESET'
const MARK_INVALID_QUERY_FAILURE = 'MARK_INVALID_QUERY_FAILURE'

const QUERY_DRAG_N_DROP = 'QUERY_DRAG_N_DROP'
const QUERY_DRAG_N_DROP_SUCCESS = 'QUERY_DRAG_N_DROP_SUCCESS'
const QUERY_DRAG_N_DROP_RESET = 'QUERY_DRAG_N_DROP_RESET'
const QUERY_DRAG_N_DROP_FAILURE = 'QUERY_DRAG_N_DROP_FAILURE'

const ANALYSIS_DRAG_N_DROP = 'ANALYSIS_DRAG_N_DROP'
const ANALYSIS_DRAG_N_DROP_SUCCESS = 'ANALYSIS_DRAG_N_DROP_SUCCESS'
const ANALYSIS_DRAG_N_DROP_RESET = 'ANALYSIS_DRAG_N_DROP_RESET'
const ANALYSIS_DRAG_N_DROP_FAILURE = 'ANALYSIS_DRAG_N_DROP_FAILURE'

const MARK_FINAL_ANALYSIS = 'MARK_FINAL_ANALYSIS';
const MARK_FINAL_ANALYSIS_RESET = 'MARK_FINAL_ANALYSIS_RESET';
const MARK_FINAL_ANALYSIS_SUCCESS = 'MARK_FINAL_ANALYSIS_SUCCESS';
const MARK_FINAL_ANALYSIS_FAILURE = 'MARK_FINAL_ANALYSIS_FAILURE';

const RUN_PROJECT = 'RUN_PROJECT'
const RUN_PROJECT_SUCCESS = 'RUN_PROJECT_SUCCESS'
const RUN_PROJECT_RESET = 'RUN_PROJECT_RESET'
const RUN_PROJECT_FAILURE = 'RUN_PROJECT_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getProjectAnalysisResetAction = () => ({
  type: GET_PROJECT_ANALYSIS_RESET,
});

export const getQueriesResetAction = () => ({
  type: GET_QUERIES_RESET,
});

// Get Analysis
export const getProjectAnalysisAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_PROJECT_ANALYSIS,
    url: `${process.env.apiUrl}v0/analyses/getanalysis`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getProjectAnalysisMoreAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_PROJECT_ANALYSIS_MORE,
    url: `${process.env.apiUrl}v0/analyses/getanalysis`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getProjectAnalysisReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PROJECT_ANALYSIS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PROJECT_ANALYSIS_SUCCESS: {
      if (action.meta.analysis_id) {
        const { analysis_id } = action.meta;
        return {
          data: {
            ...state.data, [analysis_id]: action.payload.data,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_PROJECT_ANALYSIS_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
        loadMore: true,
      }
    }

    case GET_PROJECT_ANALYSIS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_PROJECT_ANALYSIS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// Create Analysis
export const createProjectAnalysisAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: CREATE_PROJECT_ANALYSIS,
    url: `${process.env.apiUrl}v0/analyses/createanalysis`,
    method: 'POST',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Analysis added successfully',
  hideNotification: false, // hide notification on error
})

export function createProjectAnalysisReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case CREATE_PROJECT_ANALYSIS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case CREATE_PROJECT_ANALYSIS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case CREATE_PROJECT_ANALYSIS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const createProjectQueryResetAction = () => ({
  type: CREATE_PROJECT_QUERY_RESET,
})

// Create Query
export const createProjectQueryAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: CREATE_PROJECT_QUERY,
    url: `${process.env.apiUrl}v0/queries/createquery`,
    method: 'POST',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Query added successfully',
  hideNotification: false, // hide notification on error
})

export function createProjectQueryReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case CREATE_PROJECT_QUERY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case CREATE_PROJECT_QUERY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case CREATE_PROJECT_QUERY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case CREATE_PROJECT_QUERY_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

// Get Queries
export const getQueriesAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_QUERIES,
    url: `${process.env.apiUrl}v0/queries/getquery`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getQueriesReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_QUERIES: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_QUERIES_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_QUERIES_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_QUERIES_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// Update Analysis
export const updateProjectAnalysisAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: UPDATE_ANALYSIS,
      url: `${process.env.apiUrl}v0/analyses/updateanalysis?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: true,
    message: 'Analysis updated successfully',
    hideNotification: false,
  })
}

export const updateProjectAnalysisResetAction = () => ({
  type: UPDATE_ANALYSIS_RESET,
})

export function updateProjectAnalysisReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case UPDATE_ANALYSIS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case UPDATE_ANALYSIS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
        editedAnalysis: action.meta.editedAnalysis,
      }
    }
    case UPDATE_ANALYSIS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case UPDATE_ANALYSIS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// Update Query
export const updateProjectQueryAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: UPDATE_QUERY,
      url: `${process.env.apiUrl}v0/queries/updatequery?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: true,
    message: 'Query updated successfully',
    hideNotification: false,
  })
}

export const updateProjectQueryResetAction = () => ({
  type: UPDATE_QUERY_RESET,
})

export function updateProjectQueryReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case UPDATE_QUERY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case UPDATE_QUERY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case UPDATE_QUERY_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case UPDATE_QUERY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// Delete Analysis
export const deleteProjectAnalysisAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: DELETE_ANALYSIS,
      url: `${process.env.apiUrl}v0/analyses/deleteanalysis?${stringify(payload.params)}`,
      method: 'DELETE',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: true,
    message: 'Analysis deleted successfully',
    hideNotification: false,
  })
}

export const deleteProjectAnalysisResetAction = () => ({
  type: DELETE_ANALYSIS_RESET,
})

export function deleteProjectAnalysisReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case DELETE_ANALYSIS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case DELETE_ANALYSIS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case DELETE_ANALYSIS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case DELETE_ANALYSIS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// Delete Queries
export const deleteQueriesAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: DELETE_QUERIES,
    url: `${process.env.apiUrl}v0/queries/deletequery?${stringify(payload.params)}`,
    method: 'DELETE',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Query deleted successfully',
  hideNotification: false, // hide notification on error
})

export const deleteQueriesResetAction = () => ({
  type: DELETE_QUERIES_RESET,
})

export function deleteQueriesReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case DELETE_QUERIES: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case DELETE_QUERIES_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case DELETE_QUERIES_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case DELETE_QUERIES_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// Mark Invalid for Projects
export const markInvalidProjectAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: MARK_INVALID_PROJECT,
    url: `${process.env.apiUrl}v0/projects/invalidProject?${stringify(payload.params)}`,
    method: 'PUT',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: meta.removedInvalid ? 'Invalid flag removed successfully' : 'Project marked as invalid successfully',
  hideNotification: false, // hide notification on error
})

export const markInvalidProjectResetAction = () => ({
  type: MARK_INVALID_PROJECT_RESET,
})

export function markInvalidProjectReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case MARK_INVALID_PROJECT: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case MARK_INVALID_PROJECT_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case MARK_INVALID_PROJECT_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case MARK_INVALID_PROJECT_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// Mark Invalid for Analysis
export const markInvalidAnalysisAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: MARK_INVALID_ANALYSIS,
    url: `${process.env.apiUrl}v0/analyses/invalidAnalysis?${stringify(payload.params)}`,
    method: 'PUT',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: meta.removedInvalid ? 'Invalid flag removed successfully' : 'Analysis marked as invalid successfully',
  hideNotification: false, // hide notification on error
})

export const markInvalidAnalysisResetAction = () => ({
  type: MARK_INVALID_ANALYSIS_RESET,
})

export function markInvalidAnalysisReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case MARK_INVALID_ANALYSIS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case MARK_INVALID_ANALYSIS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case MARK_INVALID_ANALYSIS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case MARK_INVALID_ANALYSIS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// Mark Invalid for Query
export const markInvalidQueryAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: MARK_INVALID_QUERY,
    url: `${process.env.apiUrl}v0/queries/invalidquery?${stringify(payload.params)}`,
    method: 'PUT',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  hideNotification: false, // hide notification on error
})

export function markInvalidQueryReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case MARK_INVALID_QUERY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case MARK_INVALID_QUERY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case MARK_INVALID_QUERY_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case MARK_INVALID_QUERY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// Drag and Drop
export const queryDragNDropAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: QUERY_DRAG_N_DROP,
    url: `${process.env.apiUrl}v0/queries/updateQueryPosition?${stringify(payload.params)}`,
    method: 'PUT',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  // message: ,
  hideNotification: false, // hide notification on error
})

export const queryDragNDropResetAction = () => ({
  type: QUERY_DRAG_N_DROP_RESET,
})

export function queryDragNDropReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case QUERY_DRAG_N_DROP: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case QUERY_DRAG_N_DROP_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case QUERY_DRAG_N_DROP_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case QUERY_DRAG_N_DROP_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const analysisDragNDropAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: ANALYSIS_DRAG_N_DROP,
    url: `${process.env.apiUrl}/v0/analyses/updateAnalysisPosition?${stringify(payload.params)}`,
    method: 'PUT',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  // message: ,
  hideNotification: false, // hide notification on error
})

export const analysisDragNDropResetAction = () => ({
  type: ANALYSIS_DRAG_N_DROP_RESET,
})

export function analysisDragNDropReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case ANALYSIS_DRAG_N_DROP: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case ANALYSIS_DRAG_N_DROP_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case ANALYSIS_DRAG_N_DROP_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case ANALYSIS_DRAG_N_DROP_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// Mark Analysis Final
export const markFinalAnalysisAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: MARK_FINAL_ANALYSIS,
    url: `${process.env.apiUrl}v0/analyses/markAsFinalAnalysis?${stringify(payload.params)}`,
    method: 'PUT',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: meta.markFinal ? 'Analysis marked as "Final" successfully' : 'Removed "Final" status from analysis successfully',
  hideNotification: false, // hide notification on error
})

export const markFinalAnalysisResetAction = () => ({
  type: MARK_FINAL_ANALYSIS_RESET,
})

export function markFinalAnalysisReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case MARK_FINAL_ANALYSIS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case MARK_FINAL_ANALYSIS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case MARK_FINAL_ANALYSIS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case MARK_FINAL_ANALYSIS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const runProjectResetAction = () => ({
  type: RUN_PROJECT_RESET,
});

// Get Analysis
export const runProjectAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: RUN_PROJECT,
    url: `${process.env.apiUrl}/v0/search/runProject`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Process to save the list of investigators qualifying this project has been commenced. You will be notified via email once the process is completed and the saved results are available by clicking the view icon (eye like icon) next to the project name on project summary page.',
  hideNotification: false, // hide notification on error
})

export function runProjectReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case RUN_PROJECT: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case RUN_PROJECT_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case RUN_PROJECT_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case RUN_PROJECT_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
