import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../../container/SearchResults/logic'

const MARK_VALID_INVALID = 'MARK_VALID_INVALID'
const MARK_VALID_INVALID_SUCCESS = 'MARK_VALID_INVALID_SUCCESS'
const MARK_VALID_INVALID_RESET = 'MARK_VALID_INVALID_RESET'
const MARK_VALID_INVALID_FAILURE = 'MARK_VALID_INVALID_FAILURE'

const MARK_SITE_VALID_INVALID = 'MARK_SITE_VALID_INVALID'
const MARK_SITE_VALID_INVALID_SUCCESS = 'MARK_SITE_VALID_INVALID_SUCCESS'
const MARK_SITE_VALID_INVALID_RESET = 'MARK_SITE_VALID_INVALID_RESET'
const MARK_SITE_VALID_INVALID_FAILURE = 'MARK_SITE_VALID_INVALID_FAILURE'

const DELETE_PI = 'DELETE_PI'
const DELETE_PI_SUCCESS = 'DELETE_PI_SUCCESS'
const DELETE_PI_FAILURE = 'DELETE_PI_FAILURE'
const DELETE_PI_RESET = 'DELETE_PI_RESET'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const markValidInvalidAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: MARK_VALID_INVALID,
      url: `${process.env.apiUrl}v0/search/invalidPI?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: meta.markInvalid ? 'PI is marked as Invalid' : 'PI is marked as valid',
    hideNotification: false, // hide notification on error
  })
}

export const markValidInvalidResetAction = () => ({
  type: MARK_VALID_INVALID_RESET,
})

export function markValidInvalidReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MARK_VALID_INVALID: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case MARK_VALID_INVALID_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case MARK_VALID_INVALID_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case MARK_VALID_INVALID_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const markSiteValidInvalidAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: MARK_SITE_VALID_INVALID,
      url: `${process.env.apiUrl}v0/search/invalidSite?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: meta.markInvalid ? 'SITE is marked as Invalid' : 'SITE is marked as valid',
    hideNotification: false, // hide notification on error
  })
}

export const markSiteValidInvalidResetAction = () => ({
  type: MARK_SITE_VALID_INVALID_RESET,
})

export function markSiteValidInvalidReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MARK_SITE_VALID_INVALID: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case MARK_SITE_VALID_INVALID_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case MARK_SITE_VALID_INVALID_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case MARK_SITE_VALID_INVALID_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const deletePIAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: DELETE_PI,
      url: `${process.env.apiUrl}/v0/search/deletePiRequest?${stringify(payload.params)}`,
      method: 'DELETE',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Delete Request created',
    hideNotification: false, // hide notification on error
  })
}

export function deletePIReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_PI: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case DELETE_PI_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case DELETE_PI_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case DELETE_PI_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
