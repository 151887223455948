import { stringify } from 'query-string';
import { GENERIC_ACTION } from '../../container/SearchResults/logic'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const GET_REFERENCE_DATA = 'GET_REFERENCE_DATA';
const GET_REFERENCE_DATA_SUCCESS = 'GET_REFERENCE_DATA_SUCCESS';
const GET_REFERENCE_DATA_FAILURE = 'GET_REFERENCE_DATA_FAILURE';
const GET_REFERENCE_DATA_RESET = 'GET_REFERENCE_DATA_RESET';

export const getReferenceDataResetAction = (payload) => {
  return ({
    type: GET_REFERENCE_DATA_RESET,
    payload,
  })
}
export const getReferenceDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_REFERENCE_DATA,
      url: `${process.env.apiUrl}v0/search/listOfReferenceFiles`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    // message: 'Group created successfully',
    hideNotification: false, // hide notification on error
  })
}

export function getReferenceDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_REFERENCE_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case GET_REFERENCE_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case GET_REFERENCE_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_REFERENCE_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

const UPLOAD_REFERENCE_FILE = 'UPLOAD_REFERENCE_FILE';
const UPLOAD_REFERENCE_FILE_SUCCESS = 'UPLOAD_REFERENCE_FILE_SUCCESS';
const UPLOAD_REFERENCE_FILE_FAILURE = 'UPLOAD_REFERENCE_FILE_FAILURE';
const UPLOAD_REFERENCE_FILE_RESET = 'UPLOAD_REFERENCE_FILE_RESET';

export const uploadReferenceFileResetAction = (payload) => {
  return ({
    type: UPLOAD_REFERENCE_FILE_RESET,
    payload,
  })
}
export const uploadReferenceFileAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: UPLOAD_REFERENCE_FILE,
      url: `${process.env.apiUrl}/v0/search/uploadReferenceFiles?${stringify(payload.params)}`,
      method: 'POST',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    // message: 'Group created successfully',
    hideNotification: false, // hide notification on error
  })
}

export function uploadReferenceFileReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPLOAD_REFERENCE_FILE: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case UPLOAD_REFERENCE_FILE_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case UPLOAD_REFERENCE_FILE_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case UPLOAD_REFERENCE_FILE_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}
