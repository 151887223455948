import React from 'react';
import { Select } from 'antd';

const { Option } = Select

const RenderFilterKOLScoreClass = ({ data, setData }) => {
  const getValue = () => {
    return data.cls_kol_score_class;
  };

  const handleChange = (e) => {
    setData((old) => {
      const temp = { ...old };
      let value = '';
      value = e;
      temp.cls_kol_score_class = value;
      return temp;
    });
  };

  return (
    <div className="race-inner-wrap full-width-input">
      <div className="advanced-search-content-input-sec sources-inner-wrap">
        <div className="searchresults-filter-section-filter">
          <div className="searchresults-filter-section-filter-select">
            <div className="group-input-fields">
              <div className="value-wrap">
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select kol score class"
                  onChange={(e) => handleChange(e)}
                  maxTagCount={7}
                  value={getValue()}
                >
                  <Option value="High"> High </Option>
                  <Option value="Medium"> Moderate </Option>
                  <Option value="Low"> Low </Option>

                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RenderFilterKOLScoreClass
