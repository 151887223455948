// import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../../container/SearchResults/logic'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

const CREATE_COUNTRY_CRITERIA = 'CREATE_COUNTRY_CRITERIA'
const CREATE_COUNTRY_CRITERIA_SUCCESS = 'CREATE_COUNTRY_CRITERIA_SUCCESS'
const CREATE_COUNTRY_CRITERIA_RESET = 'CREATE_COUNTRY_CRITERIA_RESET'
const CREATE_COUNTRY_CRITERIA_FAILURE = 'CREATE_COUNTRY_CRITERIA_FAILURE'

export const createCountryCriteriaAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: CREATE_COUNTRY_CRITERIA,
      url: `${process.env.apiUrl}/v0/projects/countrySummaryRequest?project_id=${payload.project_id}`,
      method: 'POST',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const createCountryCriteriaResetAction = () => ({
  type: CREATE_COUNTRY_CRITERIA_RESET,
})

export function createCountryCriteriaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_COUNTRY_CRITERIA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case CREATE_COUNTRY_CRITERIA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case CREATE_COUNTRY_CRITERIA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case CREATE_COUNTRY_CRITERIA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const GET_COUNTRY_CRITERIA = 'GET_COUNTRY_CRITERIA'
const GET_COUNTRY_CRITERIA_SUCCESS = 'GET_COUNTRY_CRITERIA_SUCCESS'
const GET_COUNTRY_CRITERIA_RESET = 'GET_COUNTRY_CRITERIA_RESET'
const GET_COUNTRY_CRITERIA_FAILURE = 'GET_COUNTRY_CRITERIA_FAILURE'

export const getCountryCriteriaAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_COUNTRY_CRITERIA,
      url: `${process.env.apiUrl}/v0/projects/getCountrySummaryCriteria`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const getCountryCriteriaResetAction = () => ({
  type: GET_COUNTRY_CRITERIA_RESET,
})

export function getCountryCriteriaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COUNTRY_CRITERIA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_COUNTRY_CRITERIA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_COUNTRY_CRITERIA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_COUNTRY_CRITERIA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const CREATE_COUNTRY_SUMMARY = 'CREATE_COUNTRY_SUMMARY'
const CREATE_COUNTRY_SUMMARY_SUCCESS = 'CREATE_COUNTRY_SUMMARY_SUCCESS'
const CREATE_COUNTRY_SUMMARY_RESET = 'CREATE_COUNTRY_SUMMARY_RESET'
const CREATE_COUNTRY_SUMMARY_FAILURE = 'CREATE_COUNTRY_SUMMARY_FAILURE'

export const createCountrySummaryAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: CREATE_COUNTRY_SUMMARY,
      url: `${process.env.apiUrl}/v0/projects/saveCountrySelectionResults`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const createCountrySummaryResetAction = () => ({
  type: CREATE_COUNTRY_SUMMARY_RESET,
})

export function createCountrySummaryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_COUNTRY_SUMMARY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case CREATE_COUNTRY_SUMMARY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case CREATE_COUNTRY_SUMMARY_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case CREATE_COUNTRY_SUMMARY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
