import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchResultFilterAction } from '../../container/SearchResults/logic';

const { Option } = Select;

const RenderFilterTherapeuticArea = ({ data, setData, fieldToSearch }) => {
  const [therapeuticArea, setTherapeuticArea] = useState([]);

  const dispatch = useDispatch();
  const searchResultFilterData = useSelector((state) => state.searchResultFilterData);

  useEffect(() => {
    dispatch(
      getSearchResultFilterAction(
        {
          dataset: fieldToSearch === 'parent_centers' ? 'centers' : fieldToSearch,
          type: 'therapeutic_area',
        },
        {
          filter_type: 'therapeutic_area',
          key: 'therapeutic_area',
        },
      ),
    );
  }, []);

  useEffect(() => {
    if (searchResultFilterData.flag) {
      setTherapeuticArea(searchResultFilterData?.data?.therapeutic_area);
    }
  }, [JSON.stringify(searchResultFilterData)]);

  const handleChange = (e) => {
    setData((old) => {
      const temp = { ...old };
      let value = '';
      value = e;
      temp.therapeutic_area = value;
      return temp;
    });
  };

  const getValue = () => {
    return data.therapeutic_area;
  };
  return (
    <div className="race-inner-wrap full-width-input">
      <div className="advanced-search-content-input-sec sources-inner-wrap">
        <div className="searchresults-filter-section-filter">
          <div className="searchresults-filter-section-filter-select">
            <div className="group-input-fields">
              <div className="value-wrap">
                <Select
                  mode="multiple"
                  showSearch
                  allowClear
                  placeholder="Select Therapeutic Area"
                  onChange={(e) => handleChange(e)}
                  value={getValue()}
                >
                  {therapeuticArea
                    && therapeuticArea?.map((itm, index) => {
                      return (
                        <Option key={index} value={itm.key}>
                          {itm.category}
                        </Option>
                      );
                    })}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderFilterTherapeuticArea;
