import { parse } from 'query-string';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../common/components/Loader';
import { getMentionOtherSourcesDataAction } from '../../container/CTDeepDiveMentionInOtherSources/logic';

export default function CTDetailsMentionSourcesDeepDive() {
  const dispatch = useDispatch();
  const [mentionTrialDataObj, setMentionTabsTrialDataObj] = useState([])
  const { cid } = parse(location.search)
  const { idx } = parse(location.search)
  const getMentionOtherSourcesDataResponse = useSelector((store) => store.getMentionOtherSourcesDataResponse)

  useEffect(() => {
    dispatch(getMentionOtherSourcesDataAction({
      clinical_id: cid,
      from: 1,
      size: 50,
    }))
  }, [])

  useEffect(() => {
    if (getMentionOtherSourcesDataResponse.flag) {
      setMentionTabsTrialDataObj(getMentionOtherSourcesDataResponse.data.data)
    }
  }, [JSON.stringify(getMentionOtherSourcesDataResponse)])

  const getDateFormat = (ts) => {
    const dateformat = new Date(ts * 1000).toString().substring(4, 15)
    // return (` ${dateformat.getDate()} + ${(dateformat.getMonth() + 1)} + ${dateformat.getFullYear}`)
    return dateformat
  }

  return (
    <div className="main-container">
      <Loader loading={getMentionOtherSourcesDataResponse.loading} error={getMentionOtherSourcesDataResponse.error}>
        {mentionTrialDataObj && mentionTrialDataObj[idx] && (
          <div className="result-deepdivescreen">
            <div className="tab-content mentions-in-other-sources-card">
              <div className="first-line-wrapper">
                <div className="journal-title-wrapper">{mentionTrialDataObj[idx]['Journal Title']},</div>
                <div className="source-date-wrapper">{getDateFormat(mentionTrialDataObj[idx].Date)},</div>
                {mentionTrialDataObj[idx]['Full Text Link'] && (
                <div className="has-full-text-wrapper">
                  <a
                    target="_blank"
                    href={mentionTrialDataObj[idx]['Full Text Link']}
                    rel="noopener noreferrer"
                    className="header-link-text"
                  >
                    Has full text
                  </a>
                </div>
                )}
                <div className="article-title-wrapper">{mentionTrialDataObj[idx]['Article Title']}</div>
                {mentionTrialDataObj[idx].Authors && (
                  <div className="authors-content">
                    <div className="authors-content-title"> AUTHORS</div>
                    <div className="author-wrapper">{mentionTrialDataObj[idx]?.Authors?.map((author) => {
                      return (
                        <div>{author.kol_name},</div>
                      )
                    })}
                    </div>
                  </div>
                )}
                {/* <div>ONTOLOGY TAGS:</div> */}
                <div className="both-sec">
                  <div className="pmid-sec">
                    <div className="pmid-content-title">PMID</div>
                    <div>{mentionTrialDataObj[idx]['Publication ID']}</div>
                  </div>
                  <div className="doi-sec">
                    <div className="doi-content-title">DOI</div>
                    <div>{mentionTrialDataObj[idx].DOI}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Loader>
    </div>
  )
}
