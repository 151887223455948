import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../../container/SearchResults/logic'

const CREATE_SITE = 'CREATE_SITE'
const CREATE_SITE_SUCCESS = 'CREATE_SITE_SUCCESS'
const CREATE_SITE_RESET = 'CREATE_SITE_RESET'
const CREATE_SITE_FAILURE = 'CREATE_SITE_FAILURE'

const GET_SITE_QUES = 'GET_SITE_QUES'
const GET_SITE_QUES_SUCCESS = 'GET_SITE_QUES_SUCCESS'
const GET_SITE_QUES_FAILURE = 'GET_SITE_QUES_FAILURE'
const GET_SITE_QUES_RESET = 'GET_SITE_QUES_RESET'

const UPDATE_SITE = 'UPDATE_SITE'
const UPDATE_SITE_SUCCESS = 'UPDATE_SITE_SUCCESS'
const UPDATE_SITE_RESET = 'UPDATE_SITE_RESET'
const UPDATE_SITE_FAILURE = 'UPDATE_SITE_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

const ADMIN_APPROVAL = 'ADMIN_APPROVAL'
const ADMIN_APPROVAL_SUCCESS = 'ADMIN_APPROVAL_SUCCESS'
const ADMIN_APPROVAL_RESET = 'ADMIN_APPROVAL_RESET'
const ADMIN_APPROVAL_FAILURE = 'ADMIN_APPROVAL_FAILURE'

const ADMIN_APPROVAL_EDIT = 'ADMIN_APPROVAL_EDIT'
const ADMIN_APPROVAL_EDIT_SUCCESS = 'ADMIN_APPROVAL_EDIT_SUCCESS'
const ADMIN_APPROVAL_EDIT_RESET = 'ADMIN_APPROVAL_EDIT_RESET'
const ADMIN_APPROVAL_EDIT_FAILURE = 'ADMIN_APPROVAL_EDIT_FAILURE'

export const adminApprovalEditSiteAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: ADMIN_APPROVAL_EDIT,
      url: `${process.env.apiUrl}v0/search/adminApprovalEdit?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: meta.status_flag === 'Approved' ? 'SITE sucessfully added to Landscape' : meta.status_flag === 'Rejected' ? 'SITE has been rejected' : 'SITE has been sent for clarification',
    hideNotification: false, // hide notification on error
  })
}

export const adminApprovalEditSiteResetAction = () => ({
  type: ADMIN_APPROVAL_EDIT_RESET,
})

export function adminApprovalEditSiteReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case ADMIN_APPROVAL_EDIT: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case ADMIN_APPROVAL_EDIT_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case ADMIN_APPROVAL_EDIT_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case ADMIN_APPROVAL_EDIT_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const adminApprovalSiteAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: ADMIN_APPROVAL,
      url: `${process.env.apiUrl}v0/search/adminApprovalAdd?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: meta.status_flag === 'Approved' && !meta.parent_flag ? 'SITE sucessfully added to Landscape' : meta.status_flag === 'Rejected' && !meta.parent_flag ? 'SITE has been rejected' : meta.status_flag === 'Send_for_clarification' && !meta.parent_flag ? 'SITE has been sent for clarification'
      : meta.status_flag === 'Approved' && meta.parent_flag ? 'Parent institution sucessfully added to Landscape' : meta.status_flag === 'Rejected' && meta.parent_flag ? 'Parent institution has been rejected' : 'Parent institution has been sent for clarification',
    hideNotification: false, // hide notification on error
  })
}

export const adminApprovalSiteResetAction = () => ({
  type: ADMIN_APPROVAL_RESET,
})

export function adminApprovalSiteReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case ADMIN_APPROVAL: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case ADMIN_APPROVAL_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case ADMIN_APPROVAL_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case ADMIN_APPROVAL_FAILURE: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const createSiteAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: CREATE_SITE,
      url: `${process.env.apiUrl}v0/search/createsite?${stringify(payload.params)}`,
      method: 'POST',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'SITE sent for approval',
    hideNotification: false, // hide notification on error
  })
}

export const addSiteResetAction = () => ({
  type: CREATE_SITE_RESET,
})

export function createSiteReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case CREATE_SITE: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case CREATE_SITE_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case CREATE_SITE_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case CREATE_SITE_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const updateSiteAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: UPDATE_SITE,
      url: `${process.env.apiUrl}v0/search/updateSite?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Site updated successfully',
    hideNotification: false, // hide notification on error
  })
}

export const updateSiteResetAction = () => ({
  type: UPDATE_SITE_RESET,
})

export function updateSiteReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case UPDATE_SITE: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case UPDATE_SITE_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case UPDATE_SITE_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case UPDATE_SITE_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getSiteQuesDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_SITE_QUES,
      url: `${process.env.apiUrl}v0/search/siteQuestions`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getSiteQuesDataResetAction = () => ({
  type: GET_SITE_QUES_RESET,
})

export function getSiteQuesDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_SITE_QUES: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SITE_QUES_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_SITE_QUES_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_SITE_QUES_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
