import { Input } from 'antd';
import React from 'react';

const RenderFilterLandscapeIDSearch = ({ data, setData }) => {
  const getValue = () => {
    return data.landscape_id;
  };

  const handleChange = (e) => {
    setData((old) => {
      const temp = { ...old };
      const { value } = e.target
      temp.landscape_id = value.split(',').map((item) => item.trim());
      return temp;
    });
  };

  return (
    <div className="race-inner-wrap full-width-input">
      <div className="advanced-search-content-input-sec sources-inner-wrap">
        <div className="searchresults-filter-section-filter">
          <div className="searchresults-filter-section-filter-select">
            <div className="group-input-fields">
              <div className="value-wrap">
                <Input onChange={(e) => handleChange(e)} value={getValue()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RenderFilterLandscapeIDSearch
